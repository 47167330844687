import React, { PropsWithChildren } from 'react';
import Typography from 'antd/es/typography';
import './typography.less';
import { ParagraphProps } from 'antd/es/typography/Paragraph';
import classNames from 'classnames';
import { Size } from '@/ui-kit/types';

const Paragraph: React.FC<PropsWithChildren<ParagraphProps> & {
  size?: typeof Size;
}> = ({
  size,
  className,
  children,
  ...rest
}) => (
  <Typography.Paragraph
    className={classNames('ui-kit-paragraph', className, { [`font-size-${size}`]: size })}
    {...rest}
  >
    {children}
  </Typography.Paragraph>
);

export default Paragraph;
