import React, { Suspense } from 'react';
import { StrapiBanner, StrapiBlock } from '@/const/cmsInterface';

// sections that rendered first shouldn't be lazy loaded
// all banners, video and so on
import StrapiVideoComponent from '@/components/Cms/StrapiVideoComponent';
import StrapiBannerCompetitionComponent from '@/components/Cms/StrapiBannerCompetitionComponent';
import StrapiBannerComponent from '@/components/Cms/StrapiBannerComponent';
import StrapiVoteForWinnerBannerComponent from '@/components/Cms/StrapiVoteForWinnerBannerComponent';
import StrapiScreenplayCompetitionStatusComponent from '@/components/Cms/StrapiScreenplayCompetitionStatusComponent';
import StrapiAnnouncementComponent from '@/components/Cms/StrapiAnnouncementComponent';
import StrapiJudgesSessionComponent from '@/components/Cms/StrapiJudgesSessionComponent';
import get from 'lodash/get';
import StrapiCompetitionWinnerComponent from '@/components/Cms/StrapiCompetitionWinnerComponent';
import StrapiVoteForWinnerComponent from '@/components/Cms/StrapiVoteForWinnerComponent';

const StrapiSearchComponent = React.lazy(async () => import('@/components/Cms/StrapiSearchComponent'));
const StrapiContinueReadingCarouselComponent = React.lazy(async () => import('@/components/Cms/StrapiContinueReadingCarouselComponent'));
const StrapiPromotedCarouselComponent = React.lazy(async () => import('@/components/Cms/StrapiPromotedCarouselComponent'));
const StrapiCarouselChessComponent = React.lazy(async () => import('@/components/Cms/StrapiCarouselChessComponent'));
const StrapiBannerPlusContentComponent = React.lazy(async () => import('@/components/Cms/StrapiBannerPlusContentComponent'));
const StrapiCardSectionExpandableComponent = React.lazy(async () => import('@/components/Cms/StrapiCardSectionExpandableComponent'));
const StrapiScreenplayCarouselComponent = React.lazy(async () => import('@/components/Cms/StrapiScreenplayCarouselComponent'));
const StrapiScreenplayCarouselGenreSelectorComponent = React.lazy(async () => import('@/components/Cms/StrapiScreenplayCarouselGenreSelectorComponent'));
const StrapiContentPlusImageComponent = React.lazy(async () => import('@/components/Cms/StrapiContentPlusImageComponent'));
const StrapiContentPlusImagePanelComponent = React.lazy(async () => import('@/components/Cms/StrapiContentPlusImagePanelComponent'));
const StrapiCardProductSectionComponent = React.lazy(async () => import('@/components/Cms/StrapiCardProductSectionComponent'));
const StrapiContactFormComponent = React.lazy(async () => import('@/components/Cms/StrapiContactFormComponent'));
const StrapiMarkdownComponent = React.lazy(async () => import('@/components/Cms/StrapiMarkdownComponent'));
const StrapiCarouselComponent = React.lazy(async () => import('@/components/Cms/StrapiCarouselComponent'));
const StrapiParallaxComponent = React.lazy(async () => import('@/components/Cms/StrapiParallaxComponent'));
const StrapiFaqComponent = React.lazy(async () => import('@/components/Cms/StrapiFaqComponent'));
const StrapiCardSectionComponent = React.lazy(async () => import('@/components/Cms/StrapiCardSectionComponent'));
const StrapiCarouselTestimonialComponent = React.lazy(async () => import('@/components/Cms/StrapiCarouselTestimonialComponent'));
const StrapiGenresComponent = React.lazy(async () => import('@/components/Cms/StrapiGenresComponent'));

interface StrapiWrapperProperties {
  block: StrapiBlock;
}

const StrapiBlockWrapper: React.FC<StrapiWrapperProperties> = ({
  block,
}) => {
  const getBlock = () => {
    switch (block.type) {
      case 'StrapiBanner': {
        return (
          <StrapiBannerComponent block={block} key={block.id} />
        );
      }

      case 'StrapiVoteForWinnerBanner': {
        return (
          <StrapiVoteForWinnerBannerComponent block={block} key={block.id} />
        );
      }

      case 'StrapiBannerPlusContent': {
        return (
          <StrapiBannerPlusContentComponent block={block} key={block.id} />
        );
      }

      case 'StrapiBannerCompetition': {
        return (
          <StrapiBannerCompetitionComponent block={block} key={block.id} />
        );
      }

      case 'StrapiVideo': {
        return (
          <StrapiVideoComponent block={block} key={block.id} />
        );
      }

      case 'StrapiCardSection': {
        return (
          <StrapiCardSectionComponent block={block} key={block.id} />
        );
      }

      case 'StrapiCardSectionExpandable': {
        return (
          <StrapiCardSectionExpandableComponent block={block} key={block.id} />
        );
      }

      case 'StrapiCardProductSection': {
        return (
          <StrapiCardProductSectionComponent block={block} key={block.id} />
        );
      }

      case 'StrapiCarousel': {
        return (
          <StrapiCarouselComponent block={block} key={block.id} />
        );
      }

      case 'StrapiCarouselTestimonials': {
        return (
          <StrapiCarouselTestimonialComponent block={block} key={block.id} />
        );
      }

      case 'StrapiCarouselChess': {
        return (
          <StrapiCarouselChessComponent block={block} key={block.id} />
        );
      }

      case 'StrapiParallax': {
        return (
          <StrapiParallaxComponent block={block} key={block.id} />
        );
      }

      case 'StrapiAnnouncement': {
        return (
          <StrapiAnnouncementComponent block={block} key={block.id} />
        );
      }

      case 'StrapiFaq': {
        return (
          <StrapiFaqComponent block={block} key={block.id} />
        );
      }

      case 'StrapiContactForm': {
        return (
          <StrapiContactFormComponent block={block} key={block.id} />
        );
      }

      case 'StrapiMarkdown': {
        return (
          <StrapiMarkdownComponent block={block} key={block.id} />
        );
      }

      case 'StrapiContentPlusImage': {
        return (
          <StrapiContentPlusImageComponent block={block} key={block.id} />
        );
      }

      case 'StrapiContentPlusImageWithPanel': {
        return (
          <StrapiContentPlusImagePanelComponent block={block} key={block.id} />
        );
      }

      case 'StrapiScreenplayCarousel': {
        return (
          <StrapiScreenplayCarouselComponent block={block} key={block.id} />
        );
      }

      case 'StrapiContinueReadingCarousel': {
        return (
          <StrapiContinueReadingCarouselComponent block={block} key={block.id} />
        );
      }

      case 'StrapiPromotedCarousel': {
        return (
          <StrapiPromotedCarouselComponent block={block} key={block.id} />
        );
      }

      case 'StrapiScreenplayCarouselGenreSelector': {
        return (
          <StrapiScreenplayCarouselGenreSelectorComponent block={block} key={block.id} />
        );
      }

      case 'StrapiScreenplayCompetitionStatus': {
        return (
          <StrapiScreenplayCompetitionStatusComponent block={block} key={block.id} />
        );
      }

      case 'StrapiSearch': {
        return (
          <StrapiSearchComponent block={block} key={block.id} />
        );
      }

      case 'StrapiGenres': {
        return (
          <StrapiGenresComponent block={block} key={block.id} />
        );
      }

      case 'StrapiJudgesSession': {
        return (
          <StrapiJudgesSessionComponent block={block} key={block.id} />
        );
      }

      case 'StrapiVoteForWinner': {
        return (
          <StrapiVoteForWinnerComponent block={block} key={block.id} />
        );
      }

      case 'StrapiCompetitionWinner': {
        return (
          <StrapiCompetitionWinnerComponent block={block} key={block.id} />
        );
      }

      default: {
        return null;
      }
    }
  };

  const getBackgroundColor = () => {
    if (!Object.hasOwn(block, 'theme')) {
      return 'transparent';
    }
    switch (get(block, 'theme') as StrapiBanner['theme']) {
      case 'dark': {
        return 'black';
      }

      case 'dark-blue': {
        return '#121D22';
      }
      default: {
        return 'transparent';
      }
    }
  };

  // @todo height depending on screen size, move to main less or create separate file for the height
  const getFallback = () => {
    // lazy loader should have a height
    const getHeights = () => {
      if (!Object.hasOwn(block, 'height')) {
        return 0;
      }
      switch (get(block, 'height') as StrapiBanner['height']) {
        case 'full-screen': {
          return '90vh';
        }
        case 'height-640': {
          return '640px';
        }
        case 'height-770': {
          return '770px';
        }
        case 'height-530': {
          return '530px';
        }
        case 'height-380': {
          return '370px';
        }
        case 'height-100': {
          return '100px';
        }
        default: {
          return '90vh';
        }
      }
    };

    const height = getHeights();
    const backgroundColor = getBackgroundColor();

    // console.log(`hasOwn ${Object.hasOwn(block, 'height')} Loading block of type: ${block.type} id ${block.id} h ${getHeights()} align ${get(block, 'align')}`);
    return (
      <div style={{
        display: 'flex',
        color: 'transparent',
        height,
        backgroundColor,
      }}
      >
        <h1>
          {block.type}
          {' '}
          -
          {block.id}
          {' '}
          -
          {height}
          {' '}
          -
          {backgroundColor}
        </h1>
      </div>
    );
  };

  return (
    <Suspense fallback={getFallback()}>
      {getBlock()}
    </Suspense>
  );
};

export default StrapiBlockWrapper;
