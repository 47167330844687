import { StrapiCompetitionFilter } from '@/const/cmsInterface';
import If from '@/ui-kit/Base/If/If';
import React, { useMemo } from 'react';
import { CompetitionHook, useCompetition, useCurrentCompetition } from '@/hooks/useCompetition';
import dayjs from 'dayjs';
import { KlCompetitionData } from '@/api/generated/data-contracts';

interface StrapiCompetitionFilterWrapperComponentProperties {
  children: (helpers: CompetitionHook) => React.ReactNode | React.ReactNode[] | null;
  fallback?: React.ReactNode;
  isVisibleForCompetition?: StrapiCompetitionFilter[] | undefined;
  isHiddenForCompetition?: StrapiCompetitionFilter[] | undefined;
  name?: string;
}

const StrapiCompetitionFilterWrapperComponent: React.FC<StrapiCompetitionFilterWrapperComponentProperties> = ({
  children,
  fallback,
  isVisibleForCompetition,
  isHiddenForCompetition,
  name,
}) => {
  const isCompetitionFetchEnabled = !!isVisibleForCompetition?.length || !!isHiddenForCompetition?.length;
  const currentCompetition = useCurrentCompetition(isCompetitionFetchEnabled);

  // @todo check for the undefined
  const helpers = useCompetition(currentCompetition as KlCompetitionData);

  const checkIfShouldVisible = useMemo(() => {
    const result = !!currentCompetition?.rounds?.some((round) => {
      const matched = isVisibleForCompetition?.some((filter) => {
        const isRequiredToBeActive = filter?.mustBeActive !== undefined;
        if (name) {
          console.info(`Component ${name} filter isRequiredToBeActive ${isRequiredToBeActive}`, filter);
        }
        // we have to check if filter requires checking is active competition or not
        if (isRequiredToBeActive) {
          if (!round.isActive) {
            return false;
          }

          if (filter.roundSequence !== round.sequence) {
            return false;
          }
          const hasMatchedStep = round.steps?.some((step) => {
            if (!step.isActive) {
              return false;
            }
            return filter.stepSequence === step.sequence;
          });

          if (filter?.actionShouldBeActive === undefined) {
            return hasMatchedStep;
          }

          const hasMatchedAction = round.actions?.some((action) => {
            return action.isActive;
          });

          return hasMatchedStep && hasMatchedAction;
        }
        // here just check if round and step sequence match
        if (filter.roundSequence !== round.sequence) {
          if (name) {
            console.info(`Component ${name} filter.roundSequence !== round.sequence ${filter.roundSequence} !== ${round.sequence}`);
          }
          return false;
        }
        if (name) {
          console.info(`Component ${name} filter.roundSequence == round.sequence ${filter.roundSequence} == ${round.sequence} filter.mustBeActive ${filter.mustBeActive} round.isActive ${round.isActive}`);
        }

        return round.steps?.some((step) => {
          if (name) {
            console.info(`Component ${name} step ${step?.name}  isRequiredToBeActive ${isRequiredToBeActive} step.sequence ${step.sequence}`, round);
          }

          if (isRequiredToBeActive && filter.mustBeActive) {
            if (name) {
              console.info(`Component ${name} step isActive ${step.isActive}`);
            }
            return step.isActive;
          }

          // additional option to check if step sequence is after current step sequence
          if (filter?.isAfterStepSequence && step?.sequence !== undefined) {
            const isCurrentStep = filter.stepSequence === step?.sequence;
            if (!isCurrentStep) {
              return false;
            }
            // also we check that step is not active, since some step dates could be incorrect
            // @todo remove !step?.isActive
            return dayjs().isAfter(step?.activeTo as string) && !step?.isActive;
          }

          return filter.stepSequence === step.sequence;
        });
      });

      return matched;
    });
    if (name) {
      console.info(`Component ${name} is visible: ${result}`);
    }
    return result;
  }, [currentCompetition?.id]);

  if (!isVisibleForCompetition?.length && !isHiddenForCompetition?.length) {
    if (!children) {
      return null;
    }
    return (
      <>
        {children(helpers)}
      </>
    );
  }

  const isVisible = isVisibleForCompetition ? checkIfShouldVisible : true;

  const isHidden = isHiddenForCompetition ? !!currentCompetition?.rounds?.some((round) => {
    const hidden = isHiddenForCompetition?.some((filter) => {
      const isRequiredToBeActive = filter.mustBeActive !== undefined;

      if (name) {
        console.info(`isHiddenForCompetition Component ${name} isRequiredToBeActive ${isRequiredToBeActive}`);
      }
      if (isRequiredToBeActive && filter.mustBeActive) {
        return round.isActive;
      }

      if (name) {
        console.info(`isHiddenForCompetition Component ${name} filter.roundSequence !== round.sequence ${filter.roundSequence !== round.sequence}`);
      }
      if (filter.roundSequence !== round.sequence) {
        return false;
      }
      return round.steps?.some((step) => {
        return filter.stepSequence === step.sequence;
      });
    });
    return hidden;
  }) : false;

  const isChildrenShouldBeRendered = isHidden ? false : isVisible;

  return (
    <>
      <If truthy={isChildrenShouldBeRendered}>
        {children(helpers)}
      </If>
      <If truthy={!isChildrenShouldBeRendered}>
        {fallback}
      </If>
      {/* debug info if name passed as prop */}
      <If truthy={!!name}>
        <div style={{
          width: '100%', height: '300px', backgroundColor: 'transparent', color: 'white',
        }}
        >
          <div>
            {name}
            {' '}
            isVisible :
            {isVisible ? ' true' : ' false'}
            {' '}
            isHidden:
            {isHidden ? ' true' : ' false'}
            {' '}
            isChildrenShouldBeRendered
            {isChildrenShouldBeRendered ? ' true' : ' false'}
          </div>
          {JSON.stringify({
            isVisibleForCompetition, isHiddenForCompetition,
          })}
        </div>
      </If>
    </>
  );
};

export default StrapiCompetitionFilterWrapperComponent;
