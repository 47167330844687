import find from 'lodash/find';
import notification from 'antd/es/notification';

export const options = {
  onError: (error: any) => {
    const otherError = find(error?.error?.fieldErrors, { key: 'OTHER' });
    const defaultErrorText = 'Our apologies—we\'re encountering a temporary system issue.  If you continue to experience problems, feel free to contact us for assistance.';
    notification.error({
      message: 'Error',
      description: otherError?.reason ?? (error?.error?.message || defaultErrorText),
    });
  },
};
