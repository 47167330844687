import type { ReactElement } from 'react';
import { getFooterItems, getMenuItems } from '@/App';
import { getPages } from '@/const/pages';
import { StrapiBanner, StrapiPage } from '@/const/cmsInterface';
import React from 'react';
import { IMenuItem } from '@/ui-kit/types';
import StrapiBannerComponent from '@/components/Cms/StrapiBannerComponent';

// const StrapiBannerComponent = React.lazy(async () => import('@/components/Cms/StrapiBannerComponent'));

const Page = React.lazy(async () => import('@/ui-kit/Components/Layout/Page'));

export default function NotFound(): ReactElement {
  const pages = getPages() as StrapiPage[];
  const block = {
    id: '1',
    type: 'StrapiBanner',
    theme: 'dark',
    opacity: 0.5,
    displayImageAsCover: true,
    align: 'middle',
    content: [
      {
        id: '1',
        heading: {
          reverse: false,
          title: {
            text: 'Oops! This Page is Playing Hide and Seek.',
            theme: 'white',
            size: 1,
            emphasizeNewRow: true,
          },

        },
        content: 'We can’t find the page you’re looking for.',
        button: [
          {
            id: '12',
            text: 'Try again from home page',
            url: '/',
            theme: 'light',
            type: 'primary',
            transparent: true,
          },
        ],
      },
    ],
  } as StrapiBanner;

  return (
    <Page
      menuItems={getMenuItems(pages) as IMenuItem[]}
      footerItems={getFooterItems(pages)}
    >
      <StrapiBannerComponent block={block} key="not-found" />
    </Page>
  );
}
