import { StrapiAnnouncement } from '@/const/cmsInterface';
import React, { useEffect, useState } from 'react';
import StrapiCompetitionFilterWrapperComponent from '@/components/Cms/StrapiCompetitionFilterWrapperComponent';
import { useGetCompetitionsUsingGet } from '@/api/http/public';
import { useCompetition } from '@/hooks/useCompetition';
import './strapiAnnouncement.less';
import { useTranslation } from 'react-i18next';
import If from '@/ui-kit/Base/If/If';

interface StrapiAnnouncementComponentProperties {
  block: StrapiAnnouncement;
}

const MAX_STARS = 30;

const MAX_STAR_LIFE = 1;
const MIN_STAR_LIFE = 0.5;

const MAX_STAR_SIZE = 70;
const MIN_STAR_SIZE = 30;

const ANIMATION_DELAY = 23;

const MIN_STAR_TRAVEL_X = 100;
const MIN_STAR_TRAVEL_Y = 100;

const StrapiAnnouncementComponent: React.FC<StrapiAnnouncementComponentProperties> = ({
  block,
}) => {
  const { t } = useTranslation();
  const { data: competitions, isLoading } = useGetCompetitionsUsingGet(true);
  const currentCompetition = competitions?.content?.find((item) => item.isActive);
  const { getActiveStep } = useCompetition(currentCompetition);
  const activeStep = getActiveStep();

  const [isAnnouncementShown, setIsAnnouncementShown] = useState(localStorage.getItem('Announcement'));

  const random = (max: number, min: number) => {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  };

  const randomMinus = () => {
    return Math.random() > 0.5 ? 1 : -1;
  };

  const drawStar = (_: unknown, i: number) => {
    const size = random(MAX_STAR_SIZE, MIN_STAR_SIZE);

    const init_x = window?.innerWidth >= 600 ? 600 : window?.innerWidth;
    const init_y = 300;

    const x = init_x / 2 - size / 2;
    const y = init_y / 2 - size / 2;

    const x_dir = randomMinus();
    const y_dir = randomMinus();

    const x_max_travel = random(600, 100);
    const y_max_travel = random(300, 100);

    const x_travel_dist = random(x_max_travel, MIN_STAR_TRAVEL_X);
    const y_travel_dist = random(y_max_travel, MIN_STAR_TRAVEL_Y);

    const x_end = x + x_travel_dist * x_dir;
    const y_end = y + y_travel_dist * y_dir;

    const life = random(MAX_STAR_LIFE, MIN_STAR_LIFE);

    const style = {
      '--star-color': '#ffcc00',
      '--start-left': `${x}px`,
      '--start-top': `${y}px`,
      '--end-left': `${x_end}px`,
      '--end-top': `${y_end}px`,
      '--star-life': `${life}s`,
      '--star-life-num': `${life}s`,
      '--star-size': `${size}px`,
      '--animation-delay': `${i * ANIMATION_DELAY}ms`,
    } as React.CSSProperties;
    return <div className="star fa fa-star" style={style} />;
  };

  const drawStars = () => {
    return Array.from({ length: MAX_STARS }, (_, i) => drawStar(_, i));
  };

  useEffect(() => {
    if (isAnnouncementShown) {
      return;
    }
    const timeout = setTimeout(() => {
      localStorage.setItem('Announcement', 'shown');
      setIsAnnouncementShown('shown');
    }, 10 * 1000);

    return () => {
      clearInterval(timeout);
    };
  }, []);

  return (
    <StrapiCompetitionFilterWrapperComponent
      isVisibleForCompetition={block?.isVisibleForCompetition}
      isHiddenForCompetition={block?.isHiddenForCompetition}
    >
      {() => (
        <If truthy={!isLoading && !isAnnouncementShown}>
          <div
            className="overlay"
            role="button"
            tabIndex={-1}
            onClick={() => {
              // @todo refactor to use app state
              localStorage.setItem('Announcement', 'shown');
              setIsAnnouncementShown('shown');
            }}
          >
            <div className="sparkle">
              <h2 className="h1 ant-typography light">
                {activeStep?.name}
                <small>{t('Next Round Started!')}</small>
              </h2>
              {drawStars()}
            </div>
          </div>
        </If>
      )}
    </StrapiCompetitionFilterWrapperComponent>
  );
};

export default StrapiAnnouncementComponent;
