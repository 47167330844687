import './screenplays-grid.less';
import '@/ui-kit/Components/ScreenplayCarouselSection/screenplay-carousel.less';
import { useTranslation } from 'react-i18next';
import { Variant } from '@/ui-kit/types';
import classNames from 'classnames';
import { DEFAULT_PAGE_SIZE } from '@/const/httpQuery';
import { useGetPublicScriptsSearch } from '@/api/http/search';
import { KlScriptPublicData } from '@/api/generated/data-contracts';
import { useGetCompetitionsUsingGet } from '@/api/http/public';
import { useCompetition } from '@/hooks/useCompetition';
import If from '@/ui-kit/Base/If/If';
import { Paragraph, Title } from '@/ui-kit/Base/Typography';
import ScreenplayCarouselItem from '@/ui-kit/Components/ScreenplayCarouselSection/ScreenplayCarouselItem';
import List from 'antd/es/list';

export interface ViewAllProperties {
  className?: string;
  variant?: typeof Variant;
}

const activityBarColors = [
  '#c6f218',
  '#d1e000',
  '#dbcd00',
  '#e3b900',
  '#e9a500',
  '#ee9000',
  '#f17900',
  '#f26100',
  '#f14400',
  '#ee1717',
  '#ee1717',
];

const activityBarDefaultColor = '#4F6974';

const SemiFinalistsScreenplays: React.FC<ViewAllProperties> = () => {
  const { t } = useTranslation();
  const { data: competitionData, isLoading: isCompetitionLoading } = useGetCompetitionsUsingGet(true);
  const currentCompetition = competitionData?.content?.find((item) => item.isActive);
  const { isVoteForTop3 } = useCompetition(currentCompetition);

  const { data, isLoading } = useGetPublicScriptsSearch({
    pageSize: DEFAULT_PAGE_SIZE,
    pageNumber: 0,
    ...(currentCompetition?.id && { competitionIds: [currentCompetition?.id] }),
  }, false);

  // @TODO should use BE sorting
  const sortedTemporary = data?.content?.sort((a, b) => {
    return (b?.scriptVoteStatistic?.judgesRating || 0) - (a?.scriptVoteStatistic?.judgesRating || 0);
  });

  const getTitle = () => {
    if (isVoteForTop3()) {
      return t(`Top ${sortedTemporary?.length || 10} Semi-finalists`);
    }
    return t('Result');
  };

  const getActivityBar = (place: number, score: number) => {
    const currentScore = (score / 10);
    const maxScore = activityBarColors.length;

    const timeLineItems = Array.from({ length: maxScore }).map((_, i) => {
      const startColor = activityBarColors[i];
      const endColor = activityBarColors[i - 1] || activityBarColors[0];

      return {
        color: currentScore >= i ? activityBarColors[i] : activityBarDefaultColor,
        gradient: currentScore >= i && i <= (maxScore) ? `linear-gradient(${startColor}, ${endColor})` : activityBarDefaultColor,
      };
    }).reverse();

    return (
      <div className="screenplay-card-left-panel light" key={`place-${place}`}>
        <Paragraph
          className="font-size-xs lh-base font-bold ls-xxs text-uppercase secondary-045"
        >
          {`${t('top')} 3`}
        </Paragraph>
        <div className="score-line" style={{ background: 'red' }} />
        {
          timeLineItems.map((item, index) => {
            const revertedScore = maxScore - currentScore - 1;
            const isIndexUnderScore = (index) === Math.trunc(revertedScore);
            const background = isIndexUnderScore ? (timeLineItems[index + 1]?.gradient || activityBarDefaultColor) : item?.gradient;
            // taking rest of the height of line
            const height = `${100 - (index - revertedScore + 1) * 100}%`;

            return (
              <div className="score-item" key={`score-${index}`}>
                <div
                  className={classNames('score-dot', { edge: index === 0 || index === timeLineItems.length - 1 })}
                  style={{ backgroundColor: item.color }}
                />

                <If truthy={index !== maxScore}>
                  <div
                    className="score-line"
                    style={{
                      background,
                    }}
                  >
                    <If truthy={isIndexUnderScore}>
                      <div
                        className="score-line-percent"
                        style={{ background: item?.gradient, height }}
                      />
                    </If>
                  </div>
                </If>
              </div>
            );
          })
        }
      </div>
    );
  };

  return (
    <If truthy={!isLoading && !isCompetitionLoading}>
      <div className="view-all">
        <div className="view-all-content">
          <Title level={2} className="h3 lime">
            {getTitle()}
          </Title>
          <If truthy={!!sortedTemporary?.length && !isLoading}>
            <List
              className={classNames('screenplay-cards-wrapper', 'semi-finalists')}
              loadMore={null}
              pagination={false}
              dataSource={sortedTemporary}
              renderItem={(screenplay: KlScriptPublicData, i) => {
                return (
                  <li key={screenplay?.id} className="semi-finalists-item">
                    <ScreenplayCarouselItem
                      activityBar={getActivityBar(i, screenplay?.scriptVoteStatistic?.judgesRating as number)}
                      isInMyLab={screenplay.isInMyLab}
                      id={screenplay.id}
                      key={screenplay?.id}
                      imageSrc={screenplay?.posterUrl}
                      genres={screenplay?.scriptGenres?.map((genre) => genre?.name) as string[]}
                      title={screenplay?.name}
                      logline={screenplay.logline}
                      showLogline
                      hasPodcast={!!screenplay?.relatedPodcast}
                      reference={screenplay.reference}
                      voteStatistic={screenplay.scriptVoteStatistic}
                    />
                  </li>
                );
              }}
            />
          </If>
          <If truthy={!data?.content?.length && !isLoading}>
            <Paragraph className="secondary">{t('No matches found.')}</Paragraph>
          </If>
        </div>
      </div>
    </If>
  );
};

export default SemiFinalistsScreenplays;
