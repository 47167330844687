/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
	KlSubscriptionCancelSubscriptionUsingDeleteData,
	KlSubscriptionCancelSubscriptionUsingDeleteError,
	KlSubscriptionPersistUsingPostData,
	KlSubscriptionPersistUsingPostError,
	KlUpdateUserDto,
	KlUpgradeRoleDto,
	KlUserActivityGetUserActivityDetailsUsingGetData,
	KlUserActivityGetUserActivityDetailsUsingGetError,
	KlUserActivityGetUserActivityDetailsUsingGetParams,
	KlUserFollowingDto,
	KlUserFollowingFilterGetScriptLikesUsingGetData,
	KlUserFollowingFilterGetScriptLikesUsingGetError,
	KlUserFollowingFilterGetScriptLikesUsingGetParams,
	KlUserFollowingFollowUsingPostData,
	KlUserFollowingFollowUsingPostError,
	KlUserFollowingUnfollowUsingDeleteData,
	KlUserFollowingUnfollowUsingDeleteError,
	KlUserMeDeleteUserMeUsingDeleteData,
	KlUserMeDeleteUserMeUsingDeleteError,
	KlUserMeGetUserMeUsingGetData,
	KlUserMeGetUserMeUsingGetError,
	KlUserMeUpdateUserMeUsingPutData,
	KlUserMeUpdateUserMeUsingPutError,
	KlUserProfileImageDeleteUsingDeleteData,
	KlUserProfileImageDeleteUsingDeleteError,
	KlUserProfileImageDeleteUsingDeleteParamsEnum,
	KlUserProfileImageUploadUsingPostData,
	KlUserProfileImageUploadUsingPostError,
	KlUserProfileImageUploadUsingPostParamsEnum,
	KlUserProfileImageUploadUsingPostPayload,
	KlUserUpdateRoleUsingPostData,
	KlUserUpdateRoleUsingPostError
} from './data-contracts'
import { ContentType, HttpClient, RequestParams } from './http-client'

export class User<SecurityDataType = unknown> {
	http: HttpClient<SecurityDataType>

	constructor(http: HttpClient<SecurityDataType>) {
		this.http = http
	}

	/**
	 * No description
	 *
	 * @tags user-controller
	 * @name UserUpdateRoleUsingPost
	 * @summary updateRole
	 * @request POST:/api/v1/user/change-role
	 * @secure
	 * @response `200` `KlUserUpdateRoleUsingPostData` OK
	 * @response `400` `KlApiError` Bad request
	 * @response `401` `KlApiError` Unauthorized
	 * @response `404` `KlApiError` Not found
	 * @response `409` `KlApiError` Conflict
	 * @response `500` `KlApiError` Internal server error
	 */
	userUpdateRoleUsingPost = (
		request: KlUpgradeRoleDto,
		params: RequestParams = {}
	) =>
		this.http.request<
			KlUserUpdateRoleUsingPostData,
			KlUserUpdateRoleUsingPostError
		>({
			path: `/api/v1/user/change-role`,
			method: 'POST',
			body: request,
			secure: true,
			type: ContentType.Json,
			format: 'json',
			...params
		})
	/**
	 * No description
	 *
	 * @tags user-following-controller
	 * @name UserFollowingFollowUsingPost
	 * @summary follow
	 * @request POST:/api/v1/user/following
	 * @secure
	 * @response `200` `KlUserFollowingFollowUsingPostData` OK
	 * @response `400` `KlApiError` Bad request
	 * @response `401` `KlApiError` Unauthorized
	 * @response `404` `KlApiError` Not found
	 * @response `409` `KlApiError` Conflict
	 * @response `500` `KlApiError` Internal server error
	 */
	userFollowingFollowUsingPost = (
		userFollowingDto: KlUserFollowingDto,
		params: RequestParams = {}
	) =>
		this.http.request<
			KlUserFollowingFollowUsingPostData,
			KlUserFollowingFollowUsingPostError
		>({
			path: `/api/v1/user/following`,
			method: 'POST',
			body: userFollowingDto,
			secure: true,
			type: ContentType.Json,
			...params
		})
	/**
	 * No description
	 *
	 * @tags user-following-controller
	 * @name UserFollowingUnfollowUsingDelete
	 * @summary unfollow
	 * @request DELETE:/api/v1/user/following
	 * @secure
	 * @response `200` `KlUserFollowingUnfollowUsingDeleteData` OK
	 * @response `400` `KlApiError` Bad request
	 * @response `401` `KlApiError` Unauthorized
	 * @response `404` `KlApiError` Not found
	 * @response `409` `KlApiError` Conflict
	 * @response `500` `KlApiError` Internal server error
	 */
	userFollowingUnfollowUsingDelete = (
		userFollowingDto: KlUserFollowingDto,
		params: RequestParams = {}
	) =>
		this.http.request<
			KlUserFollowingUnfollowUsingDeleteData,
			KlUserFollowingUnfollowUsingDeleteError
		>({
			path: `/api/v1/user/following`,
			method: 'DELETE',
			body: userFollowingDto,
			secure: true,
			...params
		})
	/**
	 * No description
	 *
	 * @tags Filters
	 * @name UserFollowingFilterGetScriptLikesUsingGet
	 * @summary getScriptLikes
	 * @request GET:/api/v1/user/following/filter
	 * @secure
	 * @response `200` `KlUserFollowingFilterGetScriptLikesUsingGetData` OK
	 * @response `400` `KlApiError` Bad request
	 * @response `401` `KlApiError` Unauthorized
	 * @response `404` `KlApiError` Not found
	 * @response `409` `KlApiError` Conflict
	 * @response `500` `KlApiError` Internal server error
	 */
	userFollowingFilterGetScriptLikesUsingGet = (
		query: KlUserFollowingFilterGetScriptLikesUsingGetParams,
		params: RequestParams = {}
	) =>
		this.http.request<
			KlUserFollowingFilterGetScriptLikesUsingGetData,
			KlUserFollowingFilterGetScriptLikesUsingGetError
		>({
			path: `/api/v1/user/following/filter`,
			method: 'GET',
			query: query,
			secure: true,
			...params
		})
	/**
	 * No description
	 *
	 * @tags User
	 * @name UserMeGetUserMeUsingGet
	 * @summary getUserMe
	 * @request GET:/api/v1/user/me
	 * @secure
	 * @response `200` `KlUserMeGetUserMeUsingGetData` OK
	 * @response `400` `KlApiError` Bad request
	 * @response `401` `KlApiError` Unauthorized
	 * @response `404` `KlApiError` Not found
	 * @response `409` `KlApiError` Conflict
	 * @response `500` `KlApiError` Internal server error
	 */
	userMeGetUserMeUsingGet = (params: RequestParams = {}) =>
		this.http.request<
			KlUserMeGetUserMeUsingGetData,
			KlUserMeGetUserMeUsingGetError
		>({
			path: `/api/v1/user/me`,
			method: 'GET',
			secure: true,
			format: 'json',
			...params
		})
	/**
	 * No description
	 *
	 * @tags User
	 * @name UserMeUpdateUserMeUsingPut
	 * @summary updateUserMe
	 * @request PUT:/api/v1/user/me
	 * @secure
	 * @response `200` `KlUserMeUpdateUserMeUsingPutData` OK
	 * @response `400` `KlApiError` Bad request
	 * @response `401` `KlApiError` Unauthorized
	 * @response `404` `KlApiError` Not found
	 * @response `409` `KlApiError` Conflict
	 * @response `500` `KlApiError` Internal server error
	 */
	userMeUpdateUserMeUsingPut = (
		user: KlUpdateUserDto,
		params: RequestParams = {}
	) =>
		this.http.request<
			KlUserMeUpdateUserMeUsingPutData,
			KlUserMeUpdateUserMeUsingPutError
		>({
			path: `/api/v1/user/me`,
			method: 'PUT',
			body: user,
			secure: true,
			type: ContentType.Json,
			format: 'json',
			...params
		})
	/**
	 * No description
	 *
	 * @tags User
	 * @name UserMeDeleteUserMeUsingDelete
	 * @summary deleteUserMe
	 * @request DELETE:/api/v1/user/me
	 * @secure
	 * @response `200` `KlUserMeDeleteUserMeUsingDeleteData` OK
	 * @response `400` `KlApiError` Bad request
	 * @response `401` `KlApiError` Unauthorized
	 * @response `404` `KlApiError` Not found
	 * @response `409` `KlApiError` Conflict
	 * @response `500` `KlApiError` Internal server error
	 */
	userMeDeleteUserMeUsingDelete = (params: RequestParams = {}) =>
		this.http.request<
			KlUserMeDeleteUserMeUsingDeleteData,
			KlUserMeDeleteUserMeUsingDeleteError
		>({
			path: `/api/v1/user/me`,
			method: 'DELETE',
			secure: true,
			format: 'json',
			...params
		})
	/**
	 * No description
	 *
	 * @tags User
	 * @name UserProfileImageUploadUsingPost
	 * @summary upload
	 * @request POST:/api/v1/user/profile/image/{type}
	 * @secure
	 * @response `200` `KlUserProfileImageUploadUsingPostData` OK
	 * @response `400` `KlApiError` Bad request
	 * @response `401` `KlApiError` Unauthorized
	 * @response `404` `KlApiError` Not found
	 * @response `409` `KlApiError` Conflict
	 * @response `500` `KlApiError` Internal server error
	 */
	userProfileImageUploadUsingPost = (
		type: KlUserProfileImageUploadUsingPostParamsEnum,
		data: KlUserProfileImageUploadUsingPostPayload,
		params: RequestParams = {}
	) =>
		this.http.request<
			KlUserProfileImageUploadUsingPostData,
			KlUserProfileImageUploadUsingPostError
		>({
			path: `/api/v1/user/profile/image/${type}`,
			method: 'POST',
			body: data,
			secure: true,
			type: ContentType.FormData,
			...params
		})
	/**
	 * No description
	 *
	 * @tags User
	 * @name UserProfileImageDeleteUsingDelete
	 * @summary delete
	 * @request DELETE:/api/v1/user/profile/image/{type}
	 * @secure
	 * @response `200` `KlUserProfileImageDeleteUsingDeleteData` OK
	 * @response `400` `KlApiError` Bad request
	 * @response `401` `KlApiError` Unauthorized
	 * @response `404` `KlApiError` Not found
	 * @response `409` `KlApiError` Conflict
	 * @response `500` `KlApiError` Internal server error
	 */
	userProfileImageDeleteUsingDelete = (
		type: KlUserProfileImageDeleteUsingDeleteParamsEnum,
		params: RequestParams = {}
	) =>
		this.http.request<
			KlUserProfileImageDeleteUsingDeleteData,
			KlUserProfileImageDeleteUsingDeleteError
		>({
			path: `/api/v1/user/profile/image/${type}`,
			method: 'DELETE',
			secure: true,
			...params
		})
	/**
	 * No description
	 *
	 * @tags subscription-controller
	 * @name SubscriptionCancelSubscriptionUsingDelete
	 * @summary cancelSubscription
	 * @request DELETE:/api/v1/user/subscription
	 * @secure
	 * @response `200` `KlSubscriptionCancelSubscriptionUsingDeleteData` OK
	 * @response `400` `KlApiError` Bad request
	 * @response `401` `KlApiError` Unauthorized
	 * @response `404` `KlApiError` Not found
	 * @response `409` `KlApiError` Conflict
	 * @response `500` `KlApiError` Internal server error
	 */
	subscriptionCancelSubscriptionUsingDelete = (params: RequestParams = {}) =>
		this.http.request<
			KlSubscriptionCancelSubscriptionUsingDeleteData,
			KlSubscriptionCancelSubscriptionUsingDeleteError
		>({
			path: `/api/v1/user/subscription`,
			method: 'DELETE',
			secure: true,
			...params
		})
	/**
	 * No description
	 *
	 * @tags subscription-controller
	 * @name SubscriptionPersistUsingPost
	 * @summary persist
	 * @request POST:/api/v1/user/subscription/external/persist
	 * @secure
	 * @response `200` `KlSubscriptionPersistUsingPostData` OK
	 * @response `400` `KlApiError` Bad request
	 * @response `401` `KlApiError` Unauthorized
	 * @response `404` `KlApiError` Not found
	 * @response `409` `KlApiError` Conflict
	 * @response `500` `KlApiError` Internal server error
	 */
	subscriptionPersistUsingPost = (params: RequestParams = {}) =>
		this.http.request<
			KlSubscriptionPersistUsingPostData,
			KlSubscriptionPersistUsingPostError
		>({
			path: `/api/v1/user/subscription/external/persist`,
			method: 'POST',
			secure: true,
			type: ContentType.Json,
			...params
		})
	/**
	 * No description
	 *
	 * @tags user-activity-controller
	 * @name UserActivityGetUserActivityDetailsUsingGet
	 * @summary getUserActivityDetails
	 * @request GET:/api/v1/user/{id}/activity/details
	 * @secure
	 * @response `200` `KlUserActivityGetUserActivityDetailsUsingGetData` OK
	 * @response `400` `KlApiError` Bad request
	 * @response `401` `KlApiError` Unauthorized
	 * @response `404` `KlApiError` Not found
	 * @response `409` `KlApiError` Conflict
	 * @response `500` `KlApiError` Internal server error
	 */
	userActivityGetUserActivityDetailsUsingGet = (
		{ id, ...query }: KlUserActivityGetUserActivityDetailsUsingGetParams,
		params: RequestParams = {}
	) =>
		this.http.request<
			KlUserActivityGetUserActivityDetailsUsingGetData,
			KlUserActivityGetUserActivityDetailsUsingGetError
		>({
			path: `/api/v1/user/${id}/activity/details`,
			method: 'GET',
			query: query,
			secure: true,
			format: 'json',
			...params
		})
}
