// type STRAPI_PAGE = Node & {
//     readonly children: ReadonlyArray<Node>;
//     readonly createdAt: Maybe<Scalars['Date']>;
//     readonly id: Scalars['ID'];
//     readonly internal: Internal;
//     readonly isVisibleInFooter: Maybe<Scalars['Boolean']>;
//     readonly isVisibleInHeader: Maybe<Scalars['Boolean']>;
//     readonly parent: Maybe<Node>;
//     readonly publishedAt: Maybe<Scalars['Date']>;
//     readonly sections: Maybe<ReadonlyArray<Maybe<STRAPI_SECTION>>>;
//     readonly seo: Maybe<STRAPI__COMPONENT_PAGE_SEO>;
//     readonly slug: Maybe<Scalars['String']>;
//     readonly strapi_id: Maybe<Scalars['Int']>;
//     readonly theme: Maybe<Scalars['String']>;
//     readonly title: Maybe<Scalars['String']>;
//     readonly updatedAt: Maybe<Scalars['Date']>;
// };

// that paths will be managed via cms
export enum Paths {
  ROOT = '/',
  ABOUT = '/about',
  HOW_IT_WORKS = '/how-it-works',
  SCREENPLAYS = '/screenplays',
  COMPETITION = '/competition',
  SCREENPLAYS_FILTER = '/screenplays-filter',
  SCRIPT_COVERAGE = '/script-coverage',
  PRESS_PAGE = '/press',
  CAREERS = '/careers',
  CONTACTUS = '/contact-us',
  TERMS_OF_SERVICE = '/terms-of-service',
  PRIVACY_POLICY = '/privacy-policy',
  TERMS_AND_CONDITIONS = '/terms-and-conditions',
  CODE_OF_CONDUCT = '/code-of-conduct',
  SUBMISSION_RELEASE = '/submission-release',
  ACCESSIBILITY = '/accessibility',
  ARCHIVE = '/archive',
}

export enum SystemPaths {
  NOT_FOUND = '/404',
  DASHBOARD = '/dashboard',
  UNAVAILABLE = '/unavailable',
  INVITE_CODE = '/INVITE/CODE',
  PUBLIC_SCREENPLAYS = '/screenplays',
}

export enum ShopPaths {
  SHOP = '/shop',
  ORDER = '/order',
}

export enum ScreenplaysFilterPaths {
  EDITORS_PICK = '/editors-pick',
  TRENDING = '/trending',
  PODCASTS = '/podcasts',
  VIEW_ALL = '/view-all',
  GENRES = '/genres',
}

export enum DashboardPaths {
  ROOT = '/',
  PROFILE_SETTINGS = '/profile-settings',
  HISTORY = '/history',
  MY_LAB = '/my-lab',
  BILLING = '/billing',
  COUPONS = '/coupons',
  MY_SCREENPLAYS = '/my-screenplays',
  SELECT_SCREENPLAY = '/select-screenplay',
  EDIT_SCREENPLAY = '/edit-screenplay',
  PROMOTE_SCREENPLAY = '/promote-screenplay',
  SCREENPLAY_ANALYTICS = '/screenplay',
  INVITE_FRIENDS = '/invite-friends',
  SCRIPT_COVERAGE = '/script-coverage',
  NEW_SERVICE_ORDER = '/new-script-coverage',
  CONSULTATION_SERVICE = '/screenplay-consultation',
  REWARDS = '/rewards',
  DISCOVER = '/discover',
  ACCOUNT = '/account',
  BADGES = '/badges',
  UPLOAD_SCREENPLAY = '/upload-screenplay/',
  UPLOAD_SCREENPLAY_NEW = '/upload-screenplay/new',
  UPLOAD_SCREENPLAY_FINAL = '/upload-screenplay-final',
  WELCOME_NEW_USER_SETTINGS = '/welcome-new-user-settings',
  LOGIN = '/login',
  LOGOUT = '/logout',
  SIGN_UP = '/sign-up',
  NEW_USER_INVITE_FRIENDS = 'new-user-invite-friends',
}
