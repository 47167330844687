import { useApi } from '@/hooks/useApi';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { Script } from '@/api/generated/Script';

import { ContentType } from '@/api/generated/http-client';
import { Public } from '@/api/generated/Public';
import { options } from '@/api/http/helper';
import { staleTime } from '@/const/httpQuery';
import { Survey } from '@/api/generated/Survey';
import { ScriptReadingProcess } from '@/api/generated/ScriptReadingProcess';
import { Filter } from '@/api/generated/Filter';
import {
  KlMethodNameEnum, KlReviewFormsEnum, KlScriptFinalVoteDto,
  KlScriptNoteDto,
  KlScriptUploadSaveScriptBasedLifeStoryUsingPostPayload,
  KlScriptUploadSaveScriptBasedWorkUsingPostPayload,
  KlSurveySelectedAnswerDto,
  KlUploadScriptDto,
} from '@/api/generated/data-contracts';
import { ScriptVoteStatistic } from '@/api/generated/ScriptVoteStatistic';

export const useUploadScript = () => {
  const httpClient = useApi();
  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationKey: ['post:/api/v2/script'],
    mutationFn: async (params: {
      screenwriterIds?: number[];
      screenwriterNames?: string[];
      scriptAuthors?: string[];
      scriptName?: string;
      file: File;
      image: File;
    }) => {
      const {
        screenwriterIds, screenwriterNames, scriptAuthors, scriptName, file, image,
      } = params;
      return new Script(httpClient).scriptSaveScriptV2UsingPost({
        screenwriterIds, screenwriterNames, scriptAuthors, scriptName,
      }, {
        file,
        image,
      });
    },
    ...options,
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ['get:/api/v3/script/:scriptId'],
      });
    },
  });

  return mutation;
};

export const useSaveScriptImageUsingPost = () => {
  const httpClient = useApi();
  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationKey: ['post:/api/v3/script/:scriptId/image'],
    mutationFn: async (params: {
      scriptId: number;
      file: File;
    }) => {
      const {
        scriptId, file,
      } = params;
      const request = new Script(httpClient).scriptUploadSaveScriptImageUsingPost({ scriptId }, {
        image: file,
      });

      return request;
    },
    ...options,
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ['get:/api/v3/script/:scriptId'],
      });
    },
  });

  return mutation;
};

export const useGenerateAiCoverUsingPost = (scriptId: number | undefined) => {
  const httpClient = useApi();
  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationKey: ['post:/api/v3/script/:scriptId/image'],
    mutationFn: async (params: {
      scriptId: number;
    }) => {
      const {
        scriptId: id,
      } = params;
      const request = new Script(httpClient).scriptUploadSaveScriptImageUsingPost({ scriptId: id, isOpenAI: true }, {});

      return request;
    },
    ...options,
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ['get:/api/v3/script/:scriptId', scriptId],
      });
    },
  });

  return mutation;
};

export const useSaveScriptCoverByGenre = () => {
  const httpClient = useApi();
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ['post:/api/v3/script/:scriptId/image-by-genre'],
    mutationFn: async (params: {
      scriptId: number;
      imageByGenreId: number;
    }) => {
      const request = new Script(httpClient).scriptUploadSaveScriptImageByGenreUsingPost(params);
      return request;
    },
    ...options,
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ['get:/api/v3/script/:scriptId'],
      });
    },
  });
};

export const useSaveScriptCoverByAiId = (scriptId: number | undefined) => {
  const httpClient = useApi();
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ['post:/api/v3/script/:scriptId/image-ai'],
    mutationFn: async (params: {
      scriptId: number;
      imageAIId: number;
    }) => {
      const request = new Script(httpClient).scriptUploadSaveScriptImageAiUsingPost(params);
      return request;
    },
    ...options,
    onSuccess: async () => {
      if (!scriptId) {
        return;
      }
      await queryClient.invalidateQueries({
        queryKey: ['get:/api/v3/script/:scriptId', scriptId],
      });
    },
  });
};

export const useSaveScriptAICover = () => {
  const httpClient = useApi();
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ['post:/api/v3/script/:scriptId/image-ai'],
    mutationFn: async (params: {
      scriptId: number;
      imageAIId: number;
    }) => {
      const request = new Script(httpClient).scriptUploadSaveScriptImageAiUsingPost(params);
      return request;
    },
    ...options,
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ['get:/api/v3/script/:scriptId'],
      });
    },
  });
};

export const useDeleteScriptCoverByGenre = () => {
  const httpClient = useApi();
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ['post:/api/v3/script/:scriptId/image-by-genre'],
    mutationFn: async (params: {
      scriptId: number;
      imageByGenreId: number;
    }) => {
      const request = new Script(httpClient).scriptUploadDeleteScriptImageByGenreUsingDelete(params);
      return request;
    },
    ...options,
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ['get:/api/v3/script/:scriptId'],
      });
    },
  });
};

export const useUploadScriptPdfFile = () => {
  const httpClient = useApi();
  const mutation = useMutation({
    mutationKey: ['post:/api/v3/script/:scriptId/file'],
    mutationFn: async (params: {
      scriptId: number;
      file: File;
    }) => {
      const {
        scriptId, file,
      } = params;
      const request = new Script(httpClient).scriptUploadSaveScriptFileUsingPost(scriptId, {
        file,
      });
      return request;
    },
    ...options,
  });

  return mutation;
};

export const useDeleteScript = () => {
  const httpClient = useApi();
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationKey: ['DELETE:/api/v3/script/:scriptId'],
    mutationFn: async (scriptId: number) => {
      const request = new Script(httpClient).scriptRemoveUsingDelete(scriptId);
      return request;
    },
    ...options,
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ['get:/api/v1/script-upload-filter'],
      });
    },
  });

  return mutation;
};

export const useUploadScriptInitial = (scriptId: number | undefined) => {
  const httpClient = useApi();
  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationKey: ['post:/api/v3/script'],
    mutationFn: async (params: KlUploadScriptDto) => {
      const request = new Script(httpClient).scriptUploadSaveScriptUsingPost({
        ...params,
      }, {
        type: ContentType.Json,
      });

      return request;
    },
    ...options,
    onSuccess: async () => {
      if (!scriptId) {
        return;
      }
      await queryClient.invalidateQueries({
        queryKey: ['get:/api/v3/script/:scriptId', scriptId],
      });
      await queryClient.invalidateQueries({
        queryKey: ['get:/api/v3/script/scriptId/based-work', scriptId],
      });
      await queryClient.invalidateQueries({
        queryKey: ['get:/api/v3/script/scriptId/based-life-story', scriptId],
      });
    },
  });

  return mutation;
};

export const useUpdateScriptInitial = (scriptId: number | undefined) => {
  const httpClient = useApi();
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationKey: ['put:/api/v3/script/:scriptId'],
    mutationFn: async (params: {
      scriptId: number;
      isShortForm?: boolean;
      data: KlUploadScriptDto;
    }) => {
      const request = new Script(httpClient).scriptUploadUpdateScriptUsingPut({ scriptId: params.scriptId, isShortForm: params.isShortForm }, params.data, {
        type: ContentType.Json,
      });

      return request;
    },
    ...options,
    onSuccess: async () => {
      if (!scriptId) {
        return;
      }
      await queryClient.invalidateQueries({
        queryKey: ['get:/api/v3/script/:scriptId', scriptId],
      });
      await queryClient.invalidateQueries({
        queryKey: ['get:/api/v3/script/scriptId/based-work', scriptId],
      });
      await queryClient.invalidateQueries({
        queryKey: ['get:/api/v3/script/scriptId/based-life-story', scriptId],
      });
    },
  });

  return mutation;
};

export const useSaveScriptBasedLifeStoryUsingPost = (scriptId: number | undefined) => {
  const httpClient = useApi();
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationKey: ['get:/api/v3/script/:scriptId/based-life-story'],
    mutationFn: async (params: {
      scriptId: number;
      data: KlScriptUploadSaveScriptBasedLifeStoryUsingPostPayload;
    }) => {
      const request = new Script(httpClient).scriptUploadSaveScriptBasedLifeStoryUsingPost(params.scriptId, params.data);

      return request;
    },
    ...options,
    onSuccess: async () => {
      if (!scriptId) {
        return;
      }
      await queryClient.invalidateQueries({
        queryKey: ['get:/api/v3/script/scriptId/based-life-story', scriptId],
      });
    },
  });

  return mutation;
};

export const useSaveScriptBasedWorkUsingPost = (scriptId: number | undefined) => {
  const httpClient = useApi();
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationKey: ['post:/api/v3/script/scriptId/based-work'],
    mutationFn: async (params: {
      scriptId: number;
      data: KlScriptUploadSaveScriptBasedWorkUsingPostPayload;
    }) => {
      const request = new Script(httpClient).scriptUploadSaveScriptBasedWorkUsingPost(params.scriptId, params.data);

      return request;
    },
    ...options,
    onSuccess: async () => {
      if (!scriptId) {
        return;
      }
      await queryClient.invalidateQueries({
        queryKey: ['get:/api/v3/script/scriptId/based-work', scriptId],
      });
    },
  });

  return mutation;
};

export const useSubmitUploadScriptUsingPost = (scriptId: number | undefined) => {
  const httpClient = useApi();
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationKey: ['post:/api/v3/script/:scriptId/submit-upload'],
    mutationFn: async (params: {
      scriptId: number;
    }) => {
      return new Script(httpClient)
        .scriptUploadSubmitUploadScriptUsingPost(params.scriptId);
    },
    ...options,
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ['get:/api/v3/script/:scriptId', scriptId],
      });
    },
  });

  return mutation;
};

export const useUpdateUploadScriptUsingPost = (scriptId: number | undefined) => {
  const httpClient = useApi();
  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationKey: ['post:/api/v3/script/:scriptId/submit-update'],
    mutationFn: async (params: {
      scriptId: number;
    }) => {
      return new Script(httpClient)
        .scriptUploadSubmitUpdateScriptUsingPost(params.scriptId);
    },
    ...options,
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ['get:/api/v3/script/:scriptId', scriptId],
      });
      await queryClient.invalidateQueries({
        queryKey: ['get:/api/v3/script/scriptId/based-work', scriptId],
      });
      await queryClient.invalidateQueries({
        queryKey: ['get:/api/v3/script/scriptId/based-life-story', scriptId],
      });
    },
  });

  return mutation;
};

export const useScriptImageByGenre = (id: number | undefined) => {
  const httpClient = useApi();
  return useQuery({
    queryKey: ['get:/api/v1/public/script/genre/id/image', id],
    queryFn: async () => {
      if (id) {
        return new Public(httpClient).scriptImageByGenreGetUrlsByGenreUsingGet({
          id,
          pageNumber: 0,
          pageSize: 100,
        });
      }
    },
    ...options,
    enabled: !!id,
  });
};

export const useSearchScreenwriter = (searchQuery: string) => {
  const httpClient = useApi();

  return useQuery({
    queryKey: ['get:/api/v1/public/user-filter'],
    queryFn: async ({ signal }) => {
      return new Public(httpClient).userPublicFilterGetUsersUsingGet({
        role: 'SCREENWRITER',
        ...(searchQuery && { search: searchQuery }),
      }, { signal });
    },
    ...options,
  });
};

export const useGetScript = (scriptId: number | undefined) => {
  const httpClient = useApi();
  return useQuery({
    queryKey: ['get:/api/v3/script/:scriptId', scriptId],
    queryFn: async () => {
      if (!scriptId) {
        return;
      }
      const request = new Script(httpClient)
        .scriptUploadGetScriptUsingGet(scriptId);
      return request;
    },
    staleTime: 0,
    enabled: !!scriptId,
    ...options,

  });
};

export const useGetPublicScriptByReferenceUsingGet = (reference: string | undefined, methodName?: KlMethodNameEnum | undefined) => {
  const httpClient = useApi();

  return useQuery({
    queryKey: ['get:/api/v1/public/script/ref/:reference', reference],
    queryFn: async () => {
      if (!reference) {
        return;
      }

      const request = new Public(httpClient)
        .scriptPublicGetScriptPublicByReferenceUsingGet({ reference, methodName });
      return request;
    },
    staleTime: staleTime(),
    enabled: !!reference,
    ...options,

  });
};

export const useScriptTopicPostGetScriptTopicPostsUsingGet = (scriptId: number | undefined) => {
  const httpClient = useApi();
  return useQuery({
    queryKey: ['get:/api/v1/script/:scriptId/topic/post', scriptId],
    queryFn: async () => {
      if (!scriptId) {
        return;
      }
      const request = new Script(httpClient)
        .scriptTopicPostGetScriptTopicPostsUsingGet(scriptId);
      return request;
    },
    staleTime: staleTime(),
    enabled: !!scriptId,
    ...options,
  });
};

export const useGetScriptByReferenceUsingGet = (reference: string | undefined) => {
  const httpClient = useApi();
  return useQuery({
    queryKey: ['get:/api/v3/script/ref/:reference', reference],
    queryFn: async () => {
      if (!reference) {
        return;
      }
      const request = new Script(httpClient)
        .scriptUploadGetScriptByReferenceUsingGet(reference);
      return request;
    },
    staleTime: staleTime(),
    enabled: !!reference,
    ...options,

  });
};

export const useGetScriptBasedWorkUsingGet = (scriptId: number | undefined) => {
  const httpClient = useApi();
  return useQuery({
    queryKey: ['get:/api/v3/script/scriptId/based-work', scriptId],
    queryFn: async () => {
      if (!scriptId) {
        return;
      }
      return new Script(httpClient).scriptUploadGetScriptBasedWorkUsingGet(scriptId);
    },
    enabled: !!scriptId,
    ...options,

  });
};
export const useGetScriptBasedLifeStoryUsingGet = (scriptId: number | undefined) => {
  const httpClient = useApi();
  return useQuery({
    queryKey: ['get:/api/v3/script/scriptId/based-life-story', scriptId],
    queryFn: async () => {
      if (!scriptId) {
        return;
      }
      return new Script(httpClient).scriptUploadGetScriptBasedLifeStoryUsingGet(scriptId);
    },
    enabled: !!scriptId,
    ...options,

  });
};

export const useSurveyGetSurveyScriptReviewUsingGet = (scriptId: number | undefined, reviewForm: KlReviewFormsEnum) => {
  const httpClient = useApi();
  return useQuery({
    queryKey: ['@get:/api/v1/survey/vote-review', scriptId, reviewForm],
    queryFn: async () => {
      if (!scriptId) {
        return;
      }
      return new Survey(httpClient).surveyGetVoteReviewFormSurveyUsingGet({
        entityId: scriptId,
        reviewForm,
      });
    },
    enabled: !!scriptId,
    ...options,
  });
};

export const useCurrentStatusUsingGet = (entityId: number | undefined, reviewForms: KlReviewFormsEnum) => {
  const httpClient = useApi();
  return useQuery({
    queryKey: ['@get:/api/v2/survey/submit/available', entityId, reviewForms],
    queryFn: async () => {
      if (!entityId) {
        return;
      }
      return new Survey(httpClient).availableSubmitSurveyAvailableSubmitVoteReviewFormSurveysUsingGet({
        entityId,
        reviewForms,
      });
    },
    enabled: !!entityId,
    ...options,
  });
};

export const useSurveySelectedAnswerSaveVoteReviewSurveySelectedAnswersUsingPost = (scriptId: number | undefined, reviewForm: KlReviewFormsEnum) => {
  const httpClient = useApi();
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ['post:/api/v1/survey/selected-answer/script-review'],
    mutationFn: async (params: {
      answers: KlSurveySelectedAnswerDto[];
    }) => {
      if (!scriptId) {
        return;
      }
      return new Survey(httpClient).surveySelectedAnswerSaveVoteReviewSurveySelectedAnswersUsingPost({
        entityId: scriptId,
        reviewForm,
      }, params.answers);
    },
    ...options,
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        predicate: (query) => query.queryKey[0] === '@get:/api/v1/survey/vote-review',
      });
      await queryClient.invalidateQueries({
        predicate: (query) => query.queryKey[0] === '@get:/api/v2/survey/submit/available',
      });
    },
  });
};

export const useSubmitSurveySubmitVoteReviewFormSurveyUsingPost = (scriptId: number | undefined, reviewForm: KlReviewFormsEnum) => {
  const httpClient = useApi();
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ['post:/api/v1/survey/submit/vote-review'],
    mutationFn: async () => {
      if (!scriptId) {
        return;
      }
      return new Survey(httpClient).submitSurveySubmitVoteReviewFormSurveyUsingPost({
        entityId: scriptId,
        reviewForm,
      });
    },
    ...options,
    onSettled: async () => {
      await queryClient.invalidateQueries({
        predicate: (query) => query.queryKey[0] === '@get:/api/v1/survey/vote-review',
      });
      await queryClient.invalidateQueries({
        predicate: (query) => query.queryKey[0] === '@get:/api/v2/survey/submit/available',
      });
    },
  });
};

export const useScriptFinalVoteSaveFinalVoteUsingGet = (scriptId: number | undefined) => {
  const httpClient = useApi();
  return useQuery({
    queryKey: ['@get:/api/v1/script/final-vote', scriptId],
    queryFn: async () => {
      if (!scriptId) {
        return;
      }
      return new Script(httpClient).scriptFinalVoteGetFinalVoteUsingGet({
        scriptId,
      });
    },
    enabled: !!scriptId,
    ...options,
  });
};

export const useScriptFinalVoteSaveFinalVoteUsingPost = (scriptId: number | undefined) => {
  const httpClient = useApi();
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ['post:/api/v1/script/final-vote', scriptId],
    mutationFn: async (
      params: {
        data: KlScriptFinalVoteDto
      },
    ) => {
      return new Script(httpClient).scriptFinalVoteSaveFinalVoteUsingPost(params.data);
    },
    ...options,
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        predicate: (query) => query.queryKey[0] === '@get:/api/v1/script/final-vote',
      });
    },
  });
};

export const useScriptProcessingUsingPost = () => {
  const httpClient = useApi();
  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationKey: ['post:/api/v1/script-reading-process'],
    mutationFn: async (params: { scriptId: number; timeline: number[], lastPosition: number }) => {
      return new ScriptReadingProcess(httpClient).scriptReadingProcessProcessingUsingPost({
        id: params.scriptId,
        timeline: params.timeline,
        lastPosition: params.lastPosition,
      });
    },
    onSuccess: async (data) => {
      if (!data?.id) {
        return;
      }
      queryClient.setQueryData(['get:/api/v1/script-reading-process/id', data.id], data);
    },
  });

  return mutation;
};

export const useRecordFromHistoryUsingGet = (scriptId: number | undefined) => {
  const httpClient = useApi();
  return useQuery({
    queryKey: ['get:/api/v1/script-reading-process/id', scriptId],
    queryFn: async () => {
      if (!scriptId) {
        return;
      }
      return new ScriptReadingProcess(httpClient).scriptReadingProcessRecordFromHistoryUsingGet(scriptId);
    },
    enabled: !!scriptId,
    ...options,

  });
};

export const useCreateScriptNoteUsingPost = () => {
  const httpClient = useApi();
  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationKey: ['post:/api/v1/script/note'],
    mutationFn: async (params: KlScriptNoteDto) => {
      return new Script(httpClient).scriptNoteCreateScriptNoteUsingPost(params);
    },
    ...options,
    onSuccess: async (data) => {
      await queryClient.invalidateQueries({
        queryKey: ['get:/api/v1/filter/script-notes', data.scriptId],
      });
    },
  });

  return mutation;
};

export const useDeleteScriptNoteUsingDelete = (scriptId: number | undefined) => {
  const httpClient = useApi();
  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationKey: ['delete:/api/v1/script/note/:id'],
    mutationFn: async (id: number) => {
      return new Script(httpClient).scriptNoteRemoveScriptNoteUsingDelete(id);
    },
    ...options,
    onSuccess: async () => {
      if (!scriptId) {
        return;
      }
      await queryClient.invalidateQueries({
        queryKey: ['get:/api/v1/filter/script-notes', scriptId],
      });
    },
  });

  return mutation;
};

export const useGetScriptNoteUsingGet = (scriptId: number | undefined) => {
  const httpClient = useApi();
  return useQuery({
    queryKey: ['get:/api/v1/filter/script-notes', scriptId],
    queryFn: async () => {
      if (!scriptId) {
        return;
      }
      return new Filter(httpClient).scriptNoteFilterGetDataUsingGet({
        scriptId,
        pageNumber: 0,
        pageSize: 100,
      });
    },
    enabled: !!scriptId,
    ...options,

  });
};

export const useGetScriptVoteStatistics = (scriptId: number | undefined) => {
  const httpClient = useApi();
  return useQuery({
    queryKey: ['get:/api/v1/script-vote-statistic', scriptId],
    queryFn: async () => {
      if (!scriptId) {
        return;
      }
      return new ScriptVoteStatistic(httpClient)
        .scriptVoteStatisticGetScriptVoteStatisticUsingGet(scriptId);
    },
    enabled: !!scriptId,
    ...options,
  });
};

export const useGetScriptVoteStatisticsVip = (scriptId: number | undefined, enabled: boolean) => {
  const httpClient = useApi();
  return useQuery({
    queryKey: ['get:/api/v1/script-vote-statistic/vip', scriptId],
    queryFn: async () => {
      if (!scriptId) {
        return;
      }
      return new ScriptVoteStatistic(httpClient).scriptVoteStatisticGetScriptVoteStatisticVipUsingGet(scriptId);
    },
    enabled: !!scriptId && enabled,
    ...options,
  });
};
