import { StrapiVideo } from '@/const/cmsInterface';
import Text from '@/ui-kit/Base/Typography/Text';
import If from '@/ui-kit/Base/If/If';
import { InfoCircleOutlined } from '@ant-design/icons';
import { TitleProps } from 'antd/es/typography/Title';
import React from 'react';
import { AppStateInterface, useGetAppState, useUpdateAppState } from '@/api/local/app';
import Alert from 'antd/es/alert';
import VideoBanner from '@/ui-kit/Components/Banner/VideoBanner';
import CardContent from '@/ui-kit/Components/Section/CardContent/CardContent';
import Heading from '@/ui-kit/Base/Typography/Heading';

import StrapiButtonComponent from '@/components/Cms/StrapiButtonComponent';

import StrapiContentComponent from '@/components/Cms/StrapiContentComponent';
import CompetitionWrapper from '@/components/Wrappers/CompetitionWrapper';
import Timeline from '../../ui-kit/Components/Timeline/Timeline';
import { useTimeLineItems } from '@/components/Cms/hooks/useTimeLineItems';
import StrapiCompetitionFilterWrapperComponent from '@/components/Cms/StrapiCompetitionFilterWrapperComponent';
import { Banner } from '@/ui-kit/Components/Banner';

interface StrapiVideoComponentProperties {
  block: StrapiVideo;
}

const TimelineCompetitionWrapper: React.FC<StrapiVideoComponentProperties> = ({
  block,
}) => {
  const { getTimelineItems } = useTimeLineItems();
  if (!block?.hasCompetitionTimeline) {
    return null;
  }
  return (
    <CompetitionWrapper
      loader={(<Timeline items={[]} />)}
    >
      {({ getActiveStepIndex, getSteps }) => {
        const steps = getSteps();
        const activeStepIndex = getActiveStepIndex();
        const timeLineItems = getTimelineItems(steps, activeStepIndex);
        return (
          <Timeline items={timeLineItems || []} />
        );
      }}
    </CompetitionWrapper>
  );
};

const StrapiVideoComponent: React.FC<StrapiVideoComponentProperties> = ({
  block,
}) => {
  const { data: appState } = useGetAppState();
  const appStateMutation = useUpdateAppState();
  const [isAlertVisible, setIsAlertVisible] = React.useState(true);

  const videoUrl = () => {
    const isProd = import.meta.env.VITE_REACT_PUBLIC_SITE_URL as string === 'https://www.kinolime.com/';
    if (isProd) {
      return `${import.meta.env.VITE_REACT_PUBLIC_SITE_URL as string}media/e6935bc8-74ec-4a4d-85b0-674dcc08c7d8/converted/e6935bc8-74ec-4a4d-85b0-674dcc08c7d8_Kino F_30.4.mpd`;
    }
    return `${import.meta.env.VITE_REACT_PUBLIC_SITE_URL as string}media/5a7286f0-201e-49a2-bcdd-f2b961211862/converted/5a7286f0-201e-49a2-bcdd-f2b961211862_Kino F_30.4.mpd`;
  };

  return (
    <CompetitionWrapper loader={<Banner height="full-screen"><div /></Banner>}>
      {({ isFinalVote }) => (
        <If truthy={!isFinalVote()}>
          <VideoBanner
            key={block.id}
            alignment={block.align ?? 'left-bottom'}
            variant={block.theme ?? 'light'}
            autoPlay
            opacity={block.opacity ?? 1}
            videoUrl={videoUrl()}
            play={!appState?.modals?.videoModal?.isOpen}
            className={`${block.class ?? ''} ${isAlertVisible ? 'has-alert-info' : ''}`}
            fallbackImage={block.fallbackImage?.url ?? ''}
          >
            {block?.content?.map((card) => {
              return (
                <div key={card.id}>
                  <CardContent
                    key={`card-${card.id}`}
                    alt={card?.image?.alternativeText || ''}
                    img={card?.image?.url || ''}
                    controls={
                      card?.button?.map((button) => {
                        return (
                          <StrapiButtonComponent button={button} key={button?.id} />
                        );
                      })
                    }
                  >
                    <Heading
                      title={block.title?.text ?? ''}
                      subtitle={block.subtitle?.text ?? ''}
                      size={block.title?.size as TitleProps['level']}
                      headerClass={block.title?.class as any ?? 'h1'}
                      variant={block.title?.theme ?? 'light'}
                      emphasize={block.title?.emphasize ?? 'lime'}
                      emphasizeNewRow={block.title?.emphasizeNewRow ?? false}
                    />
                    {/* @todo this block should be done as mardown */}
                    <If truthy={!!card?.title}>
                      <StrapiContentComponent item={card?.title ?? ''} theme={card?.theme || 'secondary'} />
                    </If>
                    <If truthy={!!card?.content}>
                      <Text className={card?.theme || 'secondary'}>
                        <span dangerouslySetInnerHTML={{ __html: card.content || '' }} />
                      </Text>
                    </If>
                  </CardContent>

                  <div className="message-announce">
                    {block?.messageAnnounce?.map((messageAnnounce) => {
                      return (
                        <StrapiCompetitionFilterWrapperComponent
                          key={messageAnnounce?.id}
                          isVisibleForCompetition={messageAnnounce?.isVisibleForCompetition}
                        >
                          {() => {
                            if (appState?.messageAnnounce?.hiddenByUser === messageAnnounce?.id) {
                              return null;
                            }
                            setIsAlertVisible(true);
                            return (
                              <Alert
                                message={messageAnnounce?.message}
                                description={
                                  <If truthy={!!messageAnnounce?.description}>
                                    <>
                                      {messageAnnounce?.description}
                                      <a target="_blank" rel="noreferrer" href={messageAnnounce?.anchorUrl}>
                                        {messageAnnounce?.anchorText}
                                      </a>
                                    </>
                                  </If>
                                }
                                type="info"
                                showIcon
                                icon={<InfoCircleOutlined />}
                                closable
                                onClose={() => {
                                  appStateMutation.mutate({ messageAnnounce: { hiddenByUser: messageAnnounce?.id } } as Partial<AppStateInterface>);
                                  setIsAlertVisible(false);
                                }}
                              />);
                          }}
                        </StrapiCompetitionFilterWrapperComponent>
                      );
                    })}
                  </div>
                </div>
              );
            })}

            <TimelineCompetitionWrapper block={block} />
          </VideoBanner>
        </If>
      )}
    </CompetitionWrapper>
  );
};

export default StrapiVideoComponent;
