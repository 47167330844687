import React, { PropsWithChildren } from 'react';
import './typography.less';
import { LinkProps } from 'antd/es/typography/Link';
import classNames from 'classnames';
import {
  Link as RouterLink,
} from 'react-router-dom';

const Link: React.FC<PropsWithChildren<LinkProps>> = ({
  children,
  className = '',
  ...rest
}) => {
  return (
    <RouterLink to={rest.href || ''} className={classNames('ant-typography ui-kit-link', className)} {...rest}>
      {children}
    </RouterLink>
  );
};

export default Link;
