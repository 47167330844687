import { useTranslation } from 'react-i18next';
import If from '../../Base/If/If';
import classNames from 'classnames';
import './screenplays-grid.less';
import { useGetPublicScriptsSearch } from '@/api/http/search';
import { DEFAULT_PAGE_SIZE } from '@/const/httpQuery';
import { KlScriptPublicData } from '@/api/generated/data-contracts';
import { Paragraph, Title } from '@/ui-kit/Base/Typography';
import List from 'antd/es/list';
import { ScreenplayCarouselItem } from '../ScreenplayCarouselSection/ScreenplayCarouselItem';
import { useGetCompetitionsUsingGet } from '@/api/http/public';

const VoteForWinner = () => {
  const { t } = useTranslation();
  const { data: competitions } = useGetCompetitionsUsingGet(true);
  const currentCompetition = competitions?.content?.find((item) => item.isActive);

  const { data, isLoading } = useGetPublicScriptsSearch({
    pageSize: DEFAULT_PAGE_SIZE,
    pageNumber: 0,
    sort: ['scriptVoteStatistics.finalRank: asc'],
    ...(currentCompetition?.id && { competitionIds: [currentCompetition?.id] }),
  }, false);

  return (
    <If truthy={!isLoading}>
      <div className="view-all">
        <div className="view-all-content">
          <Title level={2} className="h3 lime">
            {t(`Top ${data?.content?.length} Finalists`)}
          </Title>
          <If truthy={!!data?.content?.length && !isLoading}>
            <List
              className={classNames('screenplay-cards-wrapper', 'vote-for-winner', { leaderboard: true })}
              loadMore={null}
              pagination={false}
              dataSource={data?.content}
              renderItem={(screenplay: KlScriptPublicData) => {
                return (
                  <li key={screenplay?.id}>
                    <ScreenplayCarouselItem
                      isInMyLab={screenplay.isInMyLab}
                      id={screenplay.id}
                      key={screenplay?.id}
                      imageSrc={screenplay?.posterUrl}
                      genres={screenplay?.scriptGenres?.map((genre) => genre?.name) as string[]}
                      title={screenplay?.name}
                      logline={screenplay.logline}
                      showLogline
                      reference={screenplay.reference}
                      voteStatistic={screenplay.scriptVoteStatistic}
                      podcastId={screenplay?.relatedPodcast?.id}
                      interviewId={screenplay?.relatedInterview?.id}
                      isArchived={screenplay?.isArchived}
                    />
                  </li>
                );
              }}
            />
          </If>
          <If truthy={!data?.content?.length && !isLoading}>
            <Paragraph className="secondary">{t('No matches found.')}</Paragraph>
          </If>
        </div>
      </div>
    </If>
  );
};

export default VoteForWinner;
