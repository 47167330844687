import { StrapiText } from '@/const/cmsInterface';
import Title from '@/ui-kit/Base/Typography/Title';
import If from '@/ui-kit/Base/If/If';
import { TitleProps } from 'antd/es/typography/Title';
import Heading from '@/ui-kit/Base/Typography/Heading';

interface StrapiContentComponentProperties {
  item: StrapiText | string;
  theme?: 'dark' | 'light' | 'secondary' | 'white' ; // @todo remove within the markdown
  level?: TitleProps['level']; // @todo remove within the markdown
  headerClass?: string;
  link?: string;
}

const StrapiContentComponent: React.FC<StrapiContentComponentProperties> = ({
  item,
  theme = 'secondary',
  level,
  headerClass = '',
  link,
}) => {
  if (typeof item === 'object') {
    return (
      <Heading
        title={item.text ?? ''}
        size={item.size as TitleProps['level']}
        headerClass={item.class as any ?? ''}
        variant={item.theme ?? 'light'}
        emphasize={item.emphasize ?? 'lime'}
        emphasizeNewRow={item.emphasizeNewRow ?? false}
        subtitle={item.subtitle ?? ''}
        reverse={item.reverse ?? false}
        gap={item.gap ?? 'md'}
        subtitleClass={item.subtitleClass ?? 'md'}
      />
    );
  }

  return (
    <If truthy={!!item}>
      <If truthy={!!link}>
        <Title level={level || 2} className={`${theme || 'secondary'} ${headerClass || ''}`}>
          <a href={link}>{item}</a>
        </Title>
      </If>
      <If truthy={!link}>
        <Title level={level || 2} className={`${theme || 'secondary'} ${headerClass || ''}`}>
          <span dangerouslySetInnerHTML={{ __html: item || '' }} />
        </Title>
      </If>
    </If>
  );
};

export default StrapiContentComponent;
