import React from 'react';
import { TitleProps } from 'antd/es/typography/Title';
import './heading.less';
import { Variant, Size } from '@/ui-kit/types';
import Text from '@/ui-kit/Base/Typography/Text';
import classNames from 'classnames';
import Title from '@/ui-kit/Base/Typography/Title';
import If from '@/ui-kit/Base/If/If';

export interface HeadingProperties {
  title: string;
  titleTag?: string;
  subtitle?: React.ReactNode | string;
  reverse?: boolean;
  gap?: typeof Size;
  size?: TitleProps['level'];
  headerClass?: string;
  subtitleClass?: string;
  variant?: typeof Variant;
  emphasize?: 'light' | 'lime' | 'white' | undefined;
  emphasizeNewRow?: boolean;
  backButton?: React.ReactNode;
}

const Heading: React.FC<HeadingProperties> = ({
  title,
  titleTag,
  subtitle,
  reverse = false,
  gap = 'md',
  size,
  variant = 'light',
  emphasize = 'lime' as 'light' | 'lime',
  headerClass,
  subtitleClass,
  emphasizeNewRow = false,
  backButton,
}) => {
  const getTitle = () => {
    const separator: string = title?.indexOf('#') > -1 ? '#' : title?.indexOf('%') > -1 ? '%' : '';
    if (separator) {
      const split = title?.split(separator);
      if (split.length === 3) {
        return (
          <Title
            data-tag={titleTag}
            level={size || 1}
            className={classNames(variant, headerClass, { 'ui-kit-title-tagged': !!titleTag })}
          >
            <span dangerouslySetInnerHTML={{ __html: split[0] || '' }} />
            <em className={classNames({ [`bg-${emphasize}`]: separator === '%', [emphasize]: separator === '#', 'new-row': emphasizeNewRow })}>
              <span dangerouslySetInnerHTML={{ __html: split[1] || '' }} />
            </em>
            <span dangerouslySetInnerHTML={{ __html: split[2] || '' }} />
          </Title>
        );
      }
    }
    return (
      <Title
        data-tag={titleTag}
        level={size || 1}
        className={classNames(variant, headerClass, { 'back-button': !!backButton }, { 'ui-kit-title-tagged': !!titleTag })}
      >
        <span dangerouslySetInnerHTML={{ __html: title || '' }} />
        {backButton}
      </Title>
    );
  };

  return (
    <div className={classNames('ui-kit-heading', { reverse }, `gap-${gap}`)}>
      <If truthy={!!subtitle}>
        <Text className={classNames(variant, subtitleClass)}>
          { subtitle === 'string' ? <span dangerouslySetInnerHTML={{ __html: subtitle }} /> : subtitle }
        </Text>
      </If>
      <If truthy={!!title}>
        {getTitle()}
      </If>
    </div>
  );
};

export default Heading;
