import * as Sentry from '@sentry/react';
import { sentryOption } from '@/const/sentry';

export const setupSentry = () => {
  if (import.meta.env.MODE === 'development') {
    return;
  }
  Sentry.init(sentryOption);

  console.info(`Sentry is initialized for the mode ${import.meta.env.MODE} app version ${APP_VERSION}`);
  Sentry.setTag('env', import.meta.env.MODE);
  Sentry.setTag('version', APP_VERSION?.replace('v', ''));
};

export const sentrySetTag = (key: string, value: string) => {
  if (import.meta.env.MODE === 'development') {
    return;
  }
  Sentry.setTag(key, value);
};
