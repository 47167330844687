import { StrapiButton } from '@/const/cmsInterface';
import Text from '@/ui-kit/Base/Typography/Text';
import Button from '@/ui-kit/Base/Button/Button';
import { AppStateInterface, useUpdateAppState } from '@/api/local/app';
import Popconfirm from 'antd/es/popconfirm';

import './strapiButtonComponent.less';
import {
  DashboardPaths,
  Paths,
  ShopPaths,
  SystemPaths,
} from '@/const/routes';
import { useTranslation } from 'react-i18next';
import { useGetUser } from '@/api/http/user';
import { MailOutlined, PlayCircleFilled, UploadOutlined } from '@ant-design/icons';
import StrapiCompetitionFilterWrapperComponent from '@/components/Cms/StrapiCompetitionFilterWrapperComponent';
import { ArrowCircleRightIcon, ArrowCircleUpFilledIcon, ArrowPartCircleRightIcon } from '@/ui-kit/Base/Iconography';
import { HOW_IT_WORKS_YOUTUBE_VIDEO, WATCH_OUR_MANIFESTO_YOUTUBE_VIDEO } from '@/const/videoUrls';

interface StrapiButtonComponentProperties {
  button: StrapiButton;
}
const StrapiButtonComponent: React.FC<StrapiButtonComponentProperties> = ({
  button,
}) => {
  const appStateMutation = useUpdateAppState();
  const { t } = useTranslation();
  const { data: user } = useGetUser();

  const onClickAction = async (currentButton: StrapiButton) => {
    if (currentButton?.action) {
      switch (currentButton?.action) {
        case 'video': {
          appStateMutation.mutate({
            modals: {
              videoModal: {
                isOpen: true,
                filter: currentButton.url || 'HOME_PAGE',
              },
            },
          } as Partial<AppStateInterface>);
          break;
        }
        case 'video-how-it-works': {
          appStateMutation.mutate({
            modals: {
              videoModal: {
                isOpen: true,
                filter: 'YOUTUBE',
                embeddedUrl: HOW_IT_WORKS_YOUTUBE_VIDEO,
              },
            },
          } as Partial<AppStateInterface>);
          break;
        }
        case 'youtube-how-it-works': {
          appStateMutation.mutate({
            modals: {
              videoModal: {
                isOpen: true,
                filter: currentButton.url || 'HOME_PAGE',
              },
            },
          } as Partial<AppStateInterface>);
          break;
        }
        case 'video-watch-our-manifesto': {
          appStateMutation.mutate({
            modals: {
              videoModal: {
                isOpen: true,
                filter: 'YOUTUBE',
                embeddedUrl: WATCH_OUR_MANIFESTO_YOUTUBE_VIDEO,
              },
            },
          } as Partial<AppStateInterface>);
          break;
        }
        case 'help-center': {
          window.$zopim.livechat.window.show();
          break;
        }

        case 'hint': {
          break;
        }

        case 'card-expandable': {
          if (!currentButton?.id) {
            return;
          }
          const element = document.querySelector(`#${currentButton?.id}`);
          element?.classList.add('active');
          document.querySelector('html')?.classList.add('card-bio--opened');
          break;
        }

        case 'submit-screenplay': {
          if (!user?.id) {
            window.location.href = `${SystemPaths.DASHBOARD}${DashboardPaths.LOGIN}?redirect=${window.location.origin}${SystemPaths.DASHBOARD}${DashboardPaths.UPLOAD_SCREENPLAY_NEW}`;
            return;
          }
          window.location.href = `${SystemPaths.DASHBOARD}${DashboardPaths.UPLOAD_SCREENPLAY_NEW}`;
          break;
        }
        case 'read-and-vote': {
          window.location.href = `${Paths.COMPETITION}${button?.anchor ? `#${button.anchor}` : ''}`;
          break;
        }
        case 'sign-up': {
          if (!user?.id) {
            window.location.href = `${SystemPaths.DASHBOARD}${DashboardPaths.SIGN_UP}?redirect=${window.location.origin}${SystemPaths.DASHBOARD}`;
            return;
          }
          window.location.href = `${window.location.origin}${Paths.COMPETITION}`;
          break;
        }
        case 'order-essential': {
          const targetUrl = `${window.location.origin}${SystemPaths.DASHBOARD}${DashboardPaths.SELECT_SCREENPLAY}?serviceType=${user?.subscription ? 'SCRIPT_REPORT_1_VIP' : 'SCRIPT_REPORT_1'}`;
          if (!user?.id) {
            window.location.href = `${SystemPaths.DASHBOARD}${DashboardPaths.SIGN_UP}?redirect=${targetUrl}`;
            return;
          }
          window.location.href = targetUrl;
          break;
        }
        case 'order-advanced': {
          const targetUrl = `${window.location.origin}${SystemPaths.DASHBOARD}${DashboardPaths.SELECT_SCREENPLAY}?serviceType=${user?.subscription ? 'SCRIPT_REPORT_2_VIP' : 'SCRIPT_REPORT_2'}`;
          if (!user?.id) {
            window.location.href = `${SystemPaths.DASHBOARD}${DashboardPaths.SIGN_UP}?redirect=${targetUrl}`;
            return;
          }
          window.location.href = targetUrl;
          break;
        }
        case 'join': {
          if (!user?.id) {
            window.location.href = `${SystemPaths.DASHBOARD}${DashboardPaths.LOGIN}?redirect=${window.location.origin}${SystemPaths.DASHBOARD}`;
            return;
          }
          window.location.href = `${window.location.origin}${Paths.COMPETITION}`;
          break;
        }
        case 'notify-me': {
          appStateMutation.mutate({ modals: { notifyMeModal: { isOpen: true } } } as Partial<AppStateInterface>);
          break;
        }
        case 'shop': {
          window.location.href = `${SystemPaths.DASHBOARD}${DashboardPaths.LOGIN}?redirect=${window.location.origin}${ShopPaths.SHOP}`;
          break;
        }
        default: {
          break;
        }
      }
      return;
    }

    if (!currentButton?.url) {
      return;
    }

    if (currentButton?.url?.includes('http')) {
      window.open(currentButton.url, 'current');
      return;
    }
    window.location.href = `${window.location.protocol}//${window.location.host}${currentButton.url}${button?.anchor ? `#${button.anchor}` : ''}`;
  };

  const getButtonText = (): string => {
    const isUserLoggedIn = localStorage.getItem('session');
    if (!isUserLoggedIn) {
      return button?.text || '';
    }
    if (button?.action === 'join') {
      return t('Join the community');
    }
    if (button?.action === 'sign-up') {
      return t('Join the competition');
    }

    return button?.text || '';
  };

  const renderIcon = (icon: string | undefined) => {
    switch (icon) {
      case 'ArrowCircleRightIcon': {
        return (
          <ArrowCircleRightIcon />
        );
      }
      case 'PlayCircleFilled': {
        return (
          <PlayCircleFilled />
        );
      }
      case 'ArrowCircleUpFilledIcon': {
        return (
          <ArrowCircleUpFilledIcon />
        );
      }
      case 'UploadOutlined': {
        return (
          <UploadOutlined />
        );
      }
      case 'MailOutlined': {
        return (
          <MailOutlined />
        );
      }
      case 'ArrowPartCircleRight': {
        return (
          <ArrowPartCircleRightIcon />
        );
      }
      default: {
        return null;
        break;
      }
    }
  };

  const renderButton = () => {
    const text = getButtonText();

    return (
      <StrapiCompetitionFilterWrapperComponent
        isVisibleForCompetition={button?.isVisibleForCompetition}
        isHiddenForCompetition={button?.isHiddenForCompetition}
      >
        {() => (
          <Button
            type={button?.type || 'default'}
            variant={button?.theme || 'light'}
            key={button.id}
            ghost={button?.transparent ?? false}
            iconLeft={renderIcon(button?.iconLeft)}
            iconRight={renderIcon(button?.iconRight)}
            onClick={async (event) => {
              event.preventDefault();
              event.stopPropagation();
              return onClickAction({
                ...button,
                text,
              });
            }}
            className={button?.class}
          >
            {text}
          </Button>
        )}
      </StrapiCompetitionFilterWrapperComponent>
    );
  };

  if (button?.action === 'hint') {
    return (
      <div className="button-hint">
        <Popconfirm
          title=""
          description={(
            <Text className="light secondary">
              <p dangerouslySetInnerHTML={{ __html: button?.hintText || '' }} />
            </Text>
          )}
          placement="right"
          showCancel={false}
          showArrow={false}
          getPopupContainer={(node) => {
            return node.parentElement || node;
          }}
        >
          {renderButton()}
        </Popconfirm>
      </div>
    );
  }

  return renderButton();
};

export default StrapiButtonComponent;
