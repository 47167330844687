import React, { Suspense } from 'react';
import './banner.less';
import { BannerAlignment, BannerHeight, Variant } from '@/ui-kit/types';
import classNames from 'classnames';
import If from '@/ui-kit/Base/If/If';

export interface BannerProperties {
  className?: string;
  img?: string;
  imgAsCover?: boolean;
  height?: typeof BannerHeight | undefined;
  variant?: typeof Variant;
  alignment?: typeof BannerAlignment;
  opacity?: number;
  children?: React.ReactNode | React.ReactNode[];
}

const Banner: React.FC<BannerProperties> = ({
  className,
  img = '',
  imgAsCover = true,
  height,
  variant = 'black',
  alignment = 'left',
  opacity = 0.5,
  children = null,
}) => {
  const style = {
    opacity,
  } as React.CSSProperties;

  return (
    imgAsCover ? (
      <div className={classNames('ui-kit-banner', `bg-${variant}`, alignment, { [`${height}`]: height }, className)}>
        <If truthy={!!img}>
          <img src={img} alt="" style={style} className="banner-bg" />
        </If>
        <If truthy={!!React.Children.count(children)}>
          <div className="banner-body">
            <Suspense fallback={<span />}>
              {children}
            </Suspense>
          </div>
        </If>
      </div>
    ) : (
      <div className={classNames({ 'banner-rounded': !imgAsCover })}>
        <div className={classNames('ui-kit-banner', `bg-${variant}`, alignment, { [`${height}`]: height }, className)}>
          <If truthy={!!img}>
            <img src={img} alt="" style={style} className="banner-bg" />
          </If>
          <If truthy={!!React.Children.count(children)}>
            <div className="banner-body">
              <Suspense fallback={<span />}>
                {children}
              </Suspense>
            </div>
          </If>
        </div>
      </div>
    )
  );
};

export default Banner;
