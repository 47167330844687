import { StrapiBanner, StrapiBannerCompetition } from '@/const/cmsInterface';
import Title from '@/ui-kit/Base/Typography/Title';
import Text from '@/ui-kit/Base/Typography/Text';
import Paragraph from '@/ui-kit/Base/Typography/Paragraph';
import Heading from '@/ui-kit/Base/Typography/Heading';
import If from '@/ui-kit/Base/If/If';
import { TitleProps } from 'antd/es/typography/Title';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import 'dayjs/locale/en';
import { useTranslation } from 'react-i18next';
import Flex from 'antd/es/flex';
import Statistic from 'antd/es/statistic';
import './strapiBannerCompetitionComponent.less';
import {
  LiveIcon,
} from '@/ui-kit/Base/Iconography/Icons';
import React from 'react';
import Banner from '@/ui-kit/Components/Banner/Banner';
import CardContent from '@/ui-kit/Components/Section/CardContent/CardContent';
import Timeline from '@/ui-kit/Components/Timeline/Timeline';

import StrapiButtonComponent from '@/components/Cms/StrapiButtonComponent';
import { useTimeLineItems } from '@/components/Cms/hooks/useTimeLineItems';
import Space from 'antd/es/space';
import CompetitionWrapper from '@/components/Wrappers/CompetitionWrapper';
import { CompetitionHook } from '@/hooks/useCompetition';

dayjs.extend(duration);
dayjs.locale('en');

const { Countdown } = Statistic;

interface StrapiBannerCompetitionComponentProperties {
  block: StrapiBannerCompetition;
}

const StrapiBannerCompetitionComponent: React.FC<StrapiBannerCompetitionComponentProperties> = ({
  block,
}) => {
  const { t } = useTranslation();
  const { getTimelineItems } = useTimeLineItems();

  const renderContent = (content: StrapiBanner['content'], competitionHook: CompetitionHook) => {
    if (!content?.length) {
      return null;
    }
    const {
      currentCompetition, getActiveStep, getNextActionOfActiveRound, getActiveRound,
    } = competitionHook;
    const activeStep = getActiveStep();
    const nextAction = getNextActionOfActiveRound();
    const activeRound = getActiveRound();
    const timeToStepEnds = dayjs(activeStep?.activeTo as string).endOf('day')
      .diff(dayjs(), 'milliseconds');
    const isInFuture = dayjs(currentCompetition?.activeFrom).isAfter(dayjs());

    return content?.map((card) => {
      const getSubtitle = () => {
        if (currentCompetition?.isActive) {
          const subtitle = activeStep ? (
            <Flex align="top" gap={8} className="competition-countdown">
              <LiveIcon size={16} />
              <Space direction="vertical" size={2}>
                <Title level={5} className="white main-countdown">
                  {activeStep.name}
                  {' '}
                  <span className="lime" style={{ textTransform: 'lowercase' }}>
                    <Countdown value={Date.now() + timeToStepEnds} format="DD[d] HH[h] mm[m] ss[s]" />
                  </span>
                </Title>

                <If truthy={!!nextAction?.description}>
                  <Paragraph className="secondary">
                    {nextAction?.description}
                    {' '}
                    {dayjs(nextAction?.activeFrom).format('MMMM D') }
                  </Paragraph>
                </If>
              </Space>
            </Flex>
          ) : (
            <Title level={5} className="white subtitle-countdown">
              {t('Submission started')}
            </Title>
          );

          return subtitle;
        }

        if (isInFuture) {
          return (
            <Title level={5} className="white subtitle-countdown">
              {t('Competition starts')}
              {' '}
              {dayjs(currentCompetition?.activeFrom).format('MMMM D')}
            </Title>
          );
        }

        return (
          <Title level={5} className="white subtitle-countdown">
            {t('Competition finished')}
          </Title>
        );
      };

      const sequence = activeRound?.sequence || 0;
      const titleTag = () => {
        if (!activeStep) {
          return '';
        }
        const activeAction = activeRound?.actions?.find((action) => action?.isActive);
        if (activeAction) {
          // @todo revert back
          // return activeAction?.name || '';
          return t('Final Round');
        }

        return sequence >= 0 ? `${t('Round')} ${sequence + 1}` : '';
      };

      return (
        <CardContent
          key={card.id}
          alt={card?.image?.alternativeText || ''}
          img={card?.image?.url || ''}
          controls={
            card?.button?.map((button) => {
              return (
                <StrapiButtonComponent button={button} key={button?.id} />
              );
            })
          }
        >
          <If truthy={!!card.heading?.title}>
            <Heading
              gap="lg"
              title={card.heading?.title?.text ?? ''}
              titleTag={titleTag()}
              subtitle={getSubtitle()}
              size={card.heading?.title?.size as TitleProps['level']}
              headerClass={card.heading?.title?.class ?? 'h1'}
              variant={card.heading?.title?.theme ?? 'light'}
              emphasize={card.heading?.title?.emphasize ?? 'lime'}
              emphasizeNewRow={card.heading?.title?.emphasizeNewRow ?? false}
            />

            <If truthy={!!card?.title}>
              <Title level={card?.size as any || 2} className={card?.theme || 'secondary'}>
                {typeof card.title === 'string' ? card.title : ''}
              </Title>
            </If>
            <If truthy={!!card?.content}>
              <Text className={card?.theme || 'white'}>
                <span dangerouslySetInnerHTML={{ __html: card.content || '' }} />
              </Text>
            </If>
          </If>
        </CardContent>
      );
    });
  };

  return (
    <Banner
      key={block.id}
      img={block.backgroundImage?.url ?? ''}
      alignment={block.align ?? 'alignment-timeline'}
      variant={block.theme}
      opacity={block.opacity ?? 1}
      imgAsCover={!!block.displayImageAsCover}
      height={block.height ?? 'full-screen'}
      className={block.class ?? ''}
    >
      <CompetitionWrapper
        loader={(
          <CardContent>
            <div>{ ' ' }</div>
            <Timeline items={[]} />
          </CardContent>
        )}
      >
        {(competitionHook) => {
          return (
            <>
              {renderContent(block.content, competitionHook)}
              <Timeline items={getTimelineItems(competitionHook.getSteps(), competitionHook.getActiveStepIndex()) || []} />
            </>
          );
        }}
      </CompetitionWrapper>

    </Banner>
  );
};

export default StrapiBannerCompetitionComponent;
