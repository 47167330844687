import { useParams } from 'react-router-dom';
import { useGetPublicScriptByReferenceUsingGet } from '@/api/http/screenplay';
import { KlScriptPublicData } from '@/api/generated/data-contracts';
import React from 'react';

interface ScreenplayPageWrapperProperties {
  children: (scriptData: KlScriptPublicData | undefined) => React.ReactNode | null;
}

const ScreenplayPageWrapper: React.FC<ScreenplayPageWrapperProperties> = ({
  children,
}) => {
  const { id: scriptReference } = useParams();
  const { data: scriptData, isLoading: scriptDataIsLoading } = useGetPublicScriptByReferenceUsingGet(scriptReference);

  if (scriptDataIsLoading) {
    return null;
  }

  return (
    <>
      {children(scriptData)}
    </>
  );
};

export default ScreenplayPageWrapper;
