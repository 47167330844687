import React, { PropsWithChildren } from 'react';
import Typography from 'antd/es/typography';
import { TitleProps } from 'antd/es/typography/Title';
import './title.less';
import classNames from 'classnames';

const Title: React.FC<PropsWithChildren<TitleProps>> = ({
  children,
  className,
  ...rest
}) => (
  <Typography.Title
    className={classNames('ui-kit-title', className)}
    {...rest}
  >
    {children}
  </Typography.Title>
);

export default Title;
