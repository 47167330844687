import { StrapiVoteForWinnerBanner, StrapiContent } from '@/const/cmsInterface';
import Title from '@/ui-kit/Base/Typography/Title';
import Text from '@/ui-kit/Base/Typography/Text';
import Heading from '@/ui-kit/Base/Typography/Heading';
import If from '@/ui-kit/Base/If/If';
import { TitleProps } from 'antd/es/typography/Title';
import React from 'react';
import Banner from '@/ui-kit/Components/Banner/Banner';
import CardContent from '@/ui-kit/Components/Section/CardContent/CardContent';

import StrapiButtonComponent from '@/components/Cms/StrapiButtonComponent';
import classNames from 'classnames';
import List from '../../ui-kit/Base/Typography/List';
import CompetitionWrapper from '@/components/Wrappers/CompetitionWrapper';

interface StrapiBannerComponentProperties {
  block: StrapiVoteForWinnerBanner;
}

const StrapiVoteForWinnerBannerComponent: React.FC<StrapiBannerComponentProperties> = ({
  block,
}) => {
  const renderList = (card: StrapiContent) => {
    if (!card?.list) {
      return null;
    }
    return (
      <List list={card.list} />
    );
  };

  const renderContent = (content: StrapiVoteForWinnerBanner['content']) => {
    if (!content?.length) {
      return null;
    }

    return content?.map((card) => {
      return (
        <CardContent
          key={card.id}
          alt={card?.image?.alternativeText || ''}
          img={card?.image?.url || ''}
          controls={
            card?.button?.map((button) => {
              return (
                <StrapiButtonComponent button={button} key={button?.id} />
              );
            })
          }
        >
          <If truthy={!!card.heading?.title}>
            <Heading
              title={card.heading?.title?.text ?? ''}
              subtitle={card.heading?.subtitle?.text ?? ''}
              size={card.heading?.title?.size as TitleProps['level']}
              headerClass={card.heading?.title?.class as any ?? 'h1'}
              variant={card.heading?.title?.theme ?? 'light'}
              emphasize={card.heading?.title?.emphasize ?? 'lime'}
              emphasizeNewRow={card.heading?.title?.emphasizeNewRow ?? false}
            />

            <If truthy={!!card?.title}>
              <Title level={card?.size as any || 2} className={card?.theme || 'secondary'}>
                {typeof card.title === 'string' ? card.title : ''}
              </Title>
            </If>
            {renderList(card)}

            <If truthy={!!card?.content}>
              <Text className={classNames(card?.theme || 'secondary', card.class)}>
                <span dangerouslySetInnerHTML={{ __html: card.content || '' }} />
              </Text>
            </If>
          </If>
        </CardContent>
      );
    });
  };

  return (
    <CompetitionWrapper loader={<Banner height="full-screen"><div /></Banner>}>
      {({ isFinalVote }) => (
        <If truthy={isFinalVote()}>
          <Banner
            key={`banner-${block.id}`}
            img={block.backgroundImage?.url ?? ''}
            alignment={block.align ?? 'left-bottom'}
            variant={block.theme ?? 'light'}
            opacity={block.opacity ?? 1}
            imgAsCover={!!block.displayImageAsCover}
            height={block.height ?? 'full-screen'}
            className={block.class ?? ''}
          >
            {renderContent(block.content)}
          </Banner>
        </If>
      )}
    </CompetitionWrapper>
  );
};

export default StrapiVoteForWinnerBannerComponent;
