import React, { PropsWithChildren } from 'react';
import Typography from 'antd/es/typography';
import './typography.less';
import { TextProps } from 'antd/es/typography/Text';
import classNames from 'classnames';

const Text: React.FC<PropsWithChildren<TextProps>> = ({
  className,
  children,
  ...rest
}) => {
  return (
    <Typography.Paragraph
      className={classNames('ui-kit-text', className)}
      {...rest}
    >
      {children}
    </Typography.Paragraph>
  );
};

export default Text;
