import { useTranslation } from 'react-i18next';
import { useGetPublicScriptsSearch } from '@/api/http/search';
import { SystemPaths } from '@/const/routes';
import If from '../../Base/If/If';
import classNames from 'classnames';
import Paragraph from '../../Base/Typography/Paragraph';
import Title from '../../Base/Typography/Title';
import './competition-archived-screenplyas.less';
import { KlCompetitionData } from '@/api/generated/data-contracts';
import React from 'react';

export interface CompetitionArchivedScreenplaysProperties {
  currentCompetition: KlCompetitionData;
}
const CompetitionArchivedScreenplays: React.FC<CompetitionArchivedScreenplaysProperties> = ({ currentCompetition }) => {
  const { t } = useTranslation();

  const { data: archiveScripts, isLoading: isArchiveScriptsLoading } = useGetPublicScriptsSearch({
    pageSize: 100,
    pageNumber: 0,
    ...(currentCompetition?.id && { competitionIds: [currentCompetition?.id] }),
  }, true);

  return (
    <div className={classNames('view-all', 'ui-kit-container', 'competition-winner-archive')}>
      <If truthy={!isArchiveScriptsLoading}>
        <div className="archived-screenplays">
          <Title level={2} className="h3 lime">{t('All Contestants ')}</Title>
          <div className="archived-screenplays-content">
            {
              archiveScripts?.content?.map((script) => {
                return (
                  <a key={script?.id} href={`${window.location.origin}${SystemPaths.PUBLIC_SCREENPLAYS}/${script?.reference}`}>
                    <Paragraph className="light">{script?.name}</Paragraph>
                  </a>
                );
              })
            }
          </div>
        </div>
      </If>
    </div>
  );
};

export default CompetitionArchivedScreenplays;
