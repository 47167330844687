import React from 'react';
import Typography from 'antd/es/typography';
import './typography.less';
import classNames from 'classnames';
import { StrapiText } from '@/const/cmsInterface';
import Heading from '@/ui-kit/Base/Typography/Heading';
import { TitleProps } from 'antd/es/typography/Title';

export interface ListItem {
  text: StrapiText | string;
  type: 'primary' | 'secondary';
}

export interface ListProperties {
  list: ListItem[];
  className?: string;
}

const List: React.FC<ListProperties> = ({
  list,
  className,
  ...rest
}) => {
  const renderText = (item: ListItem) => {
    if (typeof item.text !== 'string') {
      return (
        <Heading
          title={item.text.text ?? ''}
          size={item.text.size as TitleProps['level']}
          headerClass={item.text.class as any ?? ''}
          variant={item.text.theme ?? 'light'}
          emphasize={item.text.emphasize ?? 'lime'}
          emphasizeNewRow={item.text.emphasizeNewRow ?? false}
          subtitle={item.text.subtitle ?? ''}
          reverse={item.text.reverse ?? false}
          gap={item.text.gap ?? 'md'}
          subtitleClass={item.text.subtitleClass ?? 'md'}
        />
      );
    }
    return (
      <Typography.Text>{item.text}</Typography.Text>
    );
  };

  return (
    <div
      className={classNames('ui-kit-list', className)}
      {...rest}
    >
      <ul>
        {list?.map((listItem: ListItem) => {
          const key = listItem.text === 'string' ? listItem?.text : (listItem?.text as StrapiText)?.text;
          return (
            <li className={typeof listItem.text === 'string' ? listItem.type : ''} key={key}>
              {renderText(listItem)}
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default List;
